import React from 'react';
import { Collapse } from 'antd';
import asemanLogo from "../../../image/ir-terms-airlines/aseman.png";
import ataLogo from "../../../image/ir-terms-airlines/ata.png";
import caspianLogo from "../../../image/ir-terms-airlines/caspian.png";
import gheshmAirLogo from "../../../image/ir-terms-airlines/gheshm air.png";
import iranAirTourLogo from "../../../image/ir-terms-airlines/iran air tour.png";
import karonLogo from "../../../image/ir-terms-airlines/karon.png";
import kishAirLogo from "../../../image/ir-terms-airlines/kish air.png";
import mahanLogo from "../../../image/ir-terms-airlines/mahan.png";
import meragLogo from "../../../image/ir-terms-airlines/merag.png";
import parsAirLogo from "../../../image/ir-terms-airlines/pars air.png";
import iranAirLogo from "../../../image/ir-terms-airlines/iran air.png";
import sahaLogo from "../../../image/ir-terms-airlines/saha.png";
import sepehranLogo from "../../../image/ir-terms-airlines/sepehran.png";
import tabanLogo from "../../../image/ir-terms-airlines/taban.png";
import taftanLogo from "../../../image/ir-terms-airlines/taftan.png";
import vareshLogo from "../../../image/ir-terms-airlines/varesh.png";
import zagrosLogo from "../../../image/ir-terms-airlines/zagros.png";
import airTourLogo from "../../../image/ir-terms-airlines/airTourLogo.png";
import flyPersia from "../../../image/ir-terms-airlines/Fly-Persia.png";
import puyaLogo from "../../../image/ir-terms-airlines/puya.png";



const TermsFa = () => {
    const {Panel} = Collapse;
    const airlinesCancellationTables = (
      <>
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 3 روز قبل از پرواز تا 12 ظهر1 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 1 روز قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز تا 30 دقیقه قبل از پرواز</th>
                <th className="width-400">از 30 دقیقه قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={3} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">آسمان</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={asemanLogo} alt="آسمان" />
                    </span>
                  </span>
                </td>
                <td>U R X Q N T Y W V S M O </td>
                <td colSpan={2}>تا ساعت 12 ظهر روز قبل از پرواز  <span className='inline-block'>40%</span></td>
                <td colSpan={3}>از ساعت 12 ظهر روز قبل از پرواز به بعد <span className='inline-block'>60%</span></td>
              </tr>
              <tr className="bg-gray">
                <td>L K H B E</td>
                <td colSpan={2}>تا ساعت 12 ظهر روز قبل از پرواز <span className='inline-block'>50%</span></td>
                <td colSpan={3}>از ساعت 12 ظهر روز قبل از پرواز به بعد <span className='inline-block'>70%</span></td>
              </tr>
              <tr>
                <td>D I Z</td>
                <td colSpan={2}>تا ساعت 12 ظهر روز قبل از پرواز <span className='inline-block'>30%</span></td>
                <td colSpan={3}>از ساعت 12 ظهر روز قبل از پرواز به بعد <span className='inline-block'>50%</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 3 روز قبل از پرواز تا 12 ظهر1 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 1 روز قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز تا 30 دقیقه قبل از پرواز</th>
                <th className="width-400">از 30 دقیقه قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={7} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">ماهان</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={mahanLogo} alt="ماهان" />
                    </span>
                  </span>
                </td>
                <td>S X T V Q P R Y B</td>
                <td colSpan={2}>10%</td>
                <td>30%</td>
                <td colSpan={2}>50%</td>
              </tr>
              <tr className="bg-gray">
                <td>L</td>
                <td colSpan={2}>15%</td>
                <td>40%</td>
                <td colSpan={2}>50%</td>
              </tr>
              <tr>
                <td>I</td>
                <td colSpan={2}>20%</td>
                <td>40%</td>
                <td colSpan={2}>50%</td>
              </tr>
              <tr className="bg-gray">
                <td>N</td>
                <td colSpan={2}>20%</td>
                <td>50%</td>
                <td colSpan={2}>70%</td>
              </tr>
              <tr>
                <td>C</td>
                <td colSpan={2}>30%</td>
                <td>40%</td>
                <td colSpan={2}>70%</td>
              </tr>
              <tr className="bg-gray">
                <td>W WW WA</td>
                <td colSpan={2}>20%</td>
                <td>50%</td>
                <td colSpan={2}>70%</td>
              </tr>
              <tr>
                <td>U</td>
                <td colSpan={3}>90%</td>
                <td colSpan={2}>95%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 3 روز قبل از پرواز تا 12 ظهر1 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 1 روز قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز تا 30 دقیقه قبل از پرواز</th>
                <th className="width-400">از 30 دقیقه قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={4} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">کاسپین</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={caspianLogo} alt="کاسپین" />
                    </span>
                  </span>
                </td>
                <td>
                  W WF WB N NF NB X S SF H HF <br />
                  P PF PB R RF RB RH RD RE JD <br />
                  JH JB JF J Z ZF ZB ZH ZD Q <br />
                  QF QB QH QD
                </td>
                <td colSpan={2}>30%</td>
                <td colSpan={3}>50%</td>
              </tr>
              <tr className="bg-gray">
                <td>M</td>
                <td colSpan={2}>40%</td>
                <td colSpan={3}>70%</td>
              </tr>
              <tr>
                <td>K KF L LF LB LH</td>
                <td colSpan={2}>50%</td>
                <td colSpan={3}>80%</td>
              </tr>
              <tr className="bg-gray">
                <td>V A F U D IF IB IH IE I </td>
                <td colSpan={5}>100%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 3 روز قبل از پرواز تا 12 ظهر1 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 1 روز قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز تا 30 دقیقه قبل از پرواز</th>
                <th className="width-400">از 30 دقیقه قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">کیش ایر</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={kishAirLogo} alt="کیش ایر" />
                    </span>
                  </span>
                </td>
                <td>RQ RX R P F S Y B H V</td>
                <td colSpan={3}> تا 24 ساعت قبل از پرواز <span className='inline-block'>20%</span></td>
                <td colSpan={2}> از 24 ساعت قبل از پرواز به بعد <span className='inline-block'>40%</span></td>
              </tr>
              <tr className="bg-gray">
                <td>K L N Q M X</td>
                <td colSpan={3}> تا 24 ساعت قبل از پرواز <span className='inline-block'>25%</span></td>
                <td colSpan={2}> از 24 ساعت قبل از پرواز به بعد <span className='inline-block'>50%</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 3 روز قبل از پرواز تا 12 ظهر1 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 1 روز قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز تا 30 دقیقه قبل از پرواز</th>
                <th className="width-400">از 30 دقیقه قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">تفتان </span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={taftanLogo} alt="تفتان " />
                    </span>
                  </span>
                </td>
                <td>همه</td>
                <td>15%</td>
                <td>25%</td>
                <td>40%</td>
                <td>55%</td>
                <td>60%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 3 روز قبل از پرواز تا 12 ظهر1 روز قبل از پرواز</th>
                <th className="width-400">از 12 ظهر 1 روز قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز تا 30 دقیقه قبل از پرواز</th>
                <th className="width-400">از 30 دقیقه قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">ایران ایر</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={iranAirLogo} alt="ایران ایر" />
                    </span>
                  </span>
                </td>
                <td>L, X, N, M, K, Q, V, S, Y, I, C, J </td>
                <td colSpan={2}>30%</td>
                <td colSpan={3}>60%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 3 روز قبل از پرواز تا 24 ساعت قبل از پرواز</th>
                <th className="width-400">از 24 ساعت قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={5} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">آتا</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={ataLogo} alt="آتا" />
                    </span>
                  </span>
                </td>
                <td> Y I S R O V</td>
                <td>10%</td>
                <td>30%</td>
                <td>50%</td>
                <td>60%</td>
              </tr>
              <tr className="bg-gray">
                <td> B Q Z P N M</td>
                <td>20%</td>
                <td>50%</td>
                <td>70%</td>
                <td>80%</td>
              </tr>
              <tr>
                <td> L F A E D</td>
                <td>30%</td>
                <td>70%</td>
                <td>80%</td>
                <td>90%</td>
              </tr>
              <tr className="bg-gray">
                <td> K W H J</td>
                <td>40%</td>
                <td>80%</td>
                <td>90%</td>
                <td>95%</td>
              </tr>
              <tr>
                <td>X U</td>
                <td>80%</td>
                <td colSpan={3}>100%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 3 روز قبل از پرواز تا 24 ساعت قبل از پرواز</th>
                <th className="width-400">از 24 ساعت قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">پارس ایر</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={parsAirLogo} alt="پارس ایر" />
                    </span>
                  </span>
                </td>
                <td>همه</td>
                <td>30%</td>
                <td>55%</td>
                <td>65%</td>
                <td>75%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 72 ساعت قبل از پرواز</th>
                <th className="width-400">از 72 ساعت قبل از پرواز تا 48 ساعت قبل از پرواز</th>
                <th className="width-400">از 48 ساعت قبل از پرواز تا 24 ساعت قبل از پرواز</th>
                <th className="width-400">از 24 ساعت قبل از پرواز تا 12 ساعت قبل از پرواز</th>
                <th className="width-400">از 12 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">سپهران</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={sepehranLogo} alt="سپهران" />
                    </span>
                  </span>
                </td>
                <td>P PV LV V L T Q E O S H A M B R I J K Z D Y</td>
                <td>30%</td>
                <td>50%</td>
                <td>70%</td>
                <td>85%</td>
                <td>85%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 48 ساعت قبل از پرواز</th>
                <th className="width-400">از 48 ساعت قبل از پرواز تا 4 ساعت قبل از پرواز</th>
                <th className="width-400">از 4 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={6} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">معراج</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={meragLogo} alt="معراج" />
                    </span>
                  </span>
                </td>
                <td>U UB UD UE</td>
                <td>70%</td>
                <td>80%</td>
                <td>90%</td>
              </tr>
              <tr className="bg-gray">
                <td>X V Q N</td>
                <td>60%</td>
                <td>70%</td>
                <td>80%</td>
              </tr>
              <tr>
                <td>M L K H</td>
                <td>60%</td>
                <td>70%</td>
                <td>80%</td>
              </tr>
              <tr className="bg-gray">
                <td>B Y S E</td>
                <td>50%</td>
                <td>60%</td>
                <td>70%</td>
              </tr>
              <tr>
                <td>W O R A</td>
                <td>40%</td>
                <td>50%</td>
                <td>60%</td>
              </tr>
              <tr className="bg-gray">
                <td>I D C J Z</td>
                <td>30%</td>
                <td>40%</td>
                <td>50%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p>لازم به ذکر در پرواز معراج، در صورت دریافت کارت پرواز هواپیما حتی اگر از پرواز استفاده هم نشود استرداد بلیط میسر نمی باشد</p>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12:00 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 3 روز قبل از پرواز تا 12 ظهر1 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 1 روز قبل از پرواز تا 5 ساعت قبل از پرواز</th>
                <th className="width-400">از 5 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">ایر تور</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={airTourLogo} alt="ایر تور" />
                    </span>
                  </span>
                </td>
                <td> C</td>
                <td>20%</td>
                <td>30%</td>
                <td>40%</td>
                <td>50%</td>
              </tr>
              <tr className="bg-gray">
                <td> V M H Y</td>
                <td>30%</td>
                <td>40%</td>
                <td>50%</td>
                <td>60%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12:00 ظهر 1 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 1 روز قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={3} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">کارون</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={karonLogo} alt="کارون" />
                    </span>
                  </span>
                </td>
                <td>B E H K L M N Q R S U V W Y BB EB HB KB LB MB NB RB UB VB WB YB BD ED HD KD LD ND QD RD UD VD WD YD BE EE HE KE LE ME NE QE RE SE UE VE</td>
                <td>30%</td>
                <td>50%</td>
                <td>70%</td>
              </tr>
              <tr className="bg-gray">
                <td>WE YE BF EF HF KF LF MF NF QF RF SF UF VF WFYF BH EH KH LH MH NH QH RH SH UH VH WH YH BM</td>
                <td colSpan={2}>50%</td>
                <td>70%</td>
              </tr>
              <tr>
                <td>EM HM KM LM MM QM RM SM VM WM YM BN EN LN MN NN VN WN YN BO</td>
                <td colSpan={3}>100%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p>لازم به ذکر در پرواز کارون، در صورت دریافت کارت پرواز هواپیما حتی اگر از پرواز استفاده هم نشود استرداد بلیط میسر نمی باشد</p>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12:00 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 3 روز قبل از پرواز تا 12:00 ظهر 1 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 1 روز قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={3} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">تابان</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={tabanLogo} alt="تابان" />
                    </span>
                  </span>
                </td>
                <td>
                  YY YR YV YZ YL YM YA YI YP <br />
                  YF YK YD YN YB YW YJ YS YO <br />
                  YQ YE YU Y
                </td>
                <td>20%</td>
                <td>30%</td>
                <td>40%</td>
                <td>50%</td>
              </tr>
              <tr className="bg-gray">
                <td> R V Z L M A I P F K</td>
                <td>20%</td>
                <td>30%</td>
                <td>50%</td>
                <td>60%</td>
              </tr>
              <tr>
                <td>D N B W J S O Q E U </td>
                <td colSpan={4}>100%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 15 دقیقه پس از صدور</th>
                <th className="width-400">از 15 دقیقه پس از صدور بلیط تا 12:00 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 3 روز قبل از پرواز تا 12:00 ظهر 1 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 1 روز قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={3} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">وارش</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={vareshLogo} alt="وارش" />
                    </span>
                  </span>
                </td>
                <td>LL LW LV LU LS LR LQ LN LM LK LE LD LB LY LI LP LT LF LG LH LZ LO D WI</td>
                <td>بدون جریمه</td>
                <td>30%</td>
                <td>50%</td>
                <td>70%</td>
                <td>90%</td>
              </tr>
              <tr className="bg-gray">
                <td>SS SW SV SU SR SQ SN SM SK SE SD SB SH SY</td>
                <td>بدون جریمه</td>
                <td>20%</td>
                <td>40%</td>
                <td>60%</td>
                <td>80%</td>
              </tr>
              <tr>
                <td>HH HW HV HU HR HQ HN HM HI HE HD HB HS HF HJ HG HY HL HO HP HT HZ MD MF MG MH MI MK MM MN MO MP MQ MR MS MU MV MY MZ</td>
                <td>بدون جریمه</td>
                <td>10%</td>
                <td>30%</td>
                <td>50%</td>
                <td>70%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 15 دقیقه پس از صدور</th>
                <th className="width-400">از 15 دقیقه پس از صدور بلیط تا 12:00 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 3 روز قبل از پرواز تا 12:00 ظهر 1 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 1 روز قبل از پرواز تا 3 ساعت قبل از پرواز</th>
                <th className="width-400">از 3 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={4} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">زاگرس</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={zagrosLogo} alt="زاگرس" />
                    </span>
                  </span>
                </td>
                <td> D I Z</td>
                <td>بدون جریمه</td>
                <td>20%</td>
                <td>30%</td>
                <td>50%</td>
                <td>50%</td>
              </tr>
              <tr className="bg-gray">
                <td> M N Q X R U V W Y</td>
                <td>بدون جریمه</td>
                <td>30%</td>
                <td>40%</td>
                <td>50%</td>
                <td>60%</td>
              </tr>
              <tr>
                <td>H K L</td>
                <td>بدون جریمه</td>
                <td>40%</td>
                <td>50%</td>
                <td>60%</td>
                <td>70%</td>
              </tr>
              <tr className="bg-gray">
                <td>O E B</td>
                <td>بدون جریمه</td>
                <td>50%</td>
                <td>60%</td>
                <td>80%</td>
                <td>90%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p>
          <b>لازم به ذکر در پرواز زاگرس، در صورت دریافت کارت پرواز هواپیما حتی اگر از پرواز استفاده هم نشود استرداد بلیط میسر نمی باشد</b>
        </p>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12:00 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 3 روز قبل از پرواز تا 12:00 ظهر 1 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 1 روز قبل از پرواز تا 2 ساعت قبل از پرواز</th>
                <th className="width-400">از 2 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={6} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">قشم ایر</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={gheshmAirLogo} alt="قشم ایر" />
                    </span>
                  </span>
                </td>
                <td>A R Y</td>
                <td>30%</td>
                <td>40%</td>
                <td>60%</td>
                <td>70%</td>
              </tr>
              <tr className="bg-gray">
                <td>V M Q K N S</td>
                <td>40%</td>
                <td>50%</td>
                <td>60%</td>
                <td>70%</td>
              </tr>
              <tr>
                <td>D U L</td>
                <td colSpan={4}>100%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12:00 ظهر 1 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 1 روز قبل از پرواز تا 4 ساعت قبل از پرواز</th>
                <th className="width-400">از 4 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={4} className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">ساها</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={sahaLogo} alt="ساها" />
                    </span>
                  </span>
                </td>
                <td>Z D Y</td>
                <td>60%</td>
                <td>70%</td>
                <td>80%</td>
              </tr>
              <tr className="bg-gray">
                <td>P V L T Q E O S H A M B R I J K</td>
                <td>70%</td>
                <td>80%</td>
                <td>90%</td>
              </tr>
              <tr>
                <td>U W</td>
                <td colSpan={3}>95%</td>
              </tr>
              <tr className="bg-gray">
                <td>G N X F</td>
                <td colSpan={3}>100%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div className="itours-table-holder responsive-table">
          <table className="table-2 text-center">
            <thead>
              <tr>
                <th className="airline-col">ایرلاین</th>
                <th className="width-400">شناسه نرخی(کلاس)</th>
                <th className="width-400">از زمان صدور بلیط تا 12:00 ظهر 3 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 3 روز قبل از پرواز تا 12:00 ظهر 2 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 2 روز قبل از پرواز تا 12:00 ظهر 1 روز قبل از پرواز</th>
                <th className="width-400">از 12:00 ظهر 1 روز قبل از پرواز تا 4 ساعت قبل از پرواز</th>
                <th className="width-400">از 4 ساعت قبل از پرواز به بعد</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-gray">
                  <span className="airline-item">
                    <span className="airline-title">فلای پرشیا</span>
                    <span className="inline-block-middle margin-start-light">
                      <img className="airline-logo large" src={flyPersia} alt="فلای پرشیا" />
                    </span>
                  </span>
                </td>
                <td>T Y S V K Q P N L</td>
                <td>30%</td>
                <td>60%</td>
                <td>70%</td>
                <td>75%</td>
                <td>85%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
      </>
    );
    return ( <>
        <p>کاربر گرامی با توجه به قرارگیری در دوران پاندمی و بروزرسانی قوانین سفر توسط ایرلاین ها، خواهشمندیم پیش از خرید پرواز خارجی با پشتیبانی آیتورز به شماره 02191008091 در تماس باشید.</p>
        <h3 className='large-title blue2'>قوانین و مقررات</h3>
        <p>لطفا قبل از انجام سفارش مورد نظر خود، مطالب زیر را مطالعه نموده و موافقت خود را با آنها اعلام نمایید. </p>
        <ul className='red-bullet-list'>
            <li>وب‌سایت آیتورز طبق قوانین جمهوری اسلامی ایران، قوانین مرتبط با جرائم اینترنتی و مجموعه‌ی قوانین و مقررات تجارت الکترونیکی فعالیت می‌کند.</li>
            <li>کاربر گرامی، اطلاعات ثبت نامی شما (نام کاربری و رمز عبور) در حکم اطلاعات شخصی بوده و مسئولیت حفظ و حراست و استفاده از آن تماماً بر عهده شما می باشد.</li>
            <li>صحت اطلاعات ثبت نامی بر عهده کاربر می باشد و چنانچه تغییری در مشخصات هویتی فرد صورت گرفت، کاربر موظف به بروز رسانی اطلاعات خود در سایت آیتورز می باشد، در صورت نادرست بودن اطلاعات، مسئولیت آن بر عهده کاربر خواهد بود.</li>
        </ul>
        <Collapse bordered={false} className="terms-collapse" 
        //accordion 
        expandIconPosition={"left"}
         expandIcon={()=>(<span className='ant-collapse-arrow custam-css-arrow' />)}
        >
            <Panel header="شرایط و ضوابط عمومی رزرو و خرید بلیط هواپیما" key="1">
                <h3 className='medium-title blue2'>شرایط و ضوابط عمومی رزرو و خرید بلیط هواپیما</h3>
                <ul className='red-bullet-list'>
                    <li>خرید اینترنتی بلیط، به منزله آگاهی از قوانین و مقررات خرید بلیط از جانب خریدار می باشد.</li>
                    <li>با توجه به اینترنتی بودن روند فروش بلیط هواپیما در سایت آیتورز، در صورت بروز شرایط کنسلی، تاخیر یا تعجیل پرواز و یا شرایط اضطراری یا اعلام ورشکستگی ایرلاین ، شرکت هواپیمایی برگزار کننده پرواز مسئول می باشد و آیتورز مسئولیتی در این زمینه ندارد.</li>
                    <li>خریدار موظف است در هنگام ثبت نام و رزرو بلیط، اطلاعات مسافر (نام و نام خانوادگی، مسیر پرواز، ساعت پرواز، تاریخ سفر) را به درستی درج نماید، نام مسافر به صورت انگلیسی و فارسی ثبت می شود و باید دقیقا بر اساس گذرنامه وارد شود و امکان اصلاح نام پس از نهایی شدن خرید وجود ندارد، مسئولیت بروز هر گونه خطا در این زمینه با مسافر می باشد .</li>
                    <li>در صورت اشتباه وارد کردن مشخصات باید بلیط را با جریمه کنسل و مجددا خریداری نمایید.</li>
                    <li>به هیچ عنوان نام خود را به صورت خلاصه و یا مخفف ذکر نکنید. </li>
                    <li>طبق قانون کشورهای اروپایی در برخی از موارد، خط هوایی خسارت تاخیر، کنسل شدن، و جلوگیری از سوارشدن مسافر به هواپیما را پرداخت یا کل پول را باز میگرداند. این اطلاعات معمولا در فرودگاه‌های اروپایی به مسافران داده شده، و یا در وبسایت خط هوایی وجود دارد. جبران این خسارت برعهده خط هوایی است.</li>
                    <li>در گونه تغییر در نوع هواپیما بر عهده شرکت هواپیمایی برگزارکننده پرواز است و آیتورز مسئولیتی در این زمینه ندارد.</li>
                    <li>اگر مسافر تغییر در زمان پرواز را نپذیرد، باید به آیتورز اطلاع دهد. </li>
                    <li>بر اساس قوانین امکان انتقال بلیط خریداری شده به فرد دیگر وجود ندارد، عدم تطابق نام مسافر در بلیط و کارت شناسایی، ممانعت پرواز مسافر از جانب فرودگاه را به همراه خواهد داشت.</li>
                    <li>کنسلی و استرداد بلیط‌های سیستمی داخلی و خارجی به درخواست مسافر، مطابق قوانین مندرج در بلیط و اعلام‌شده از طرف شرکت هواپیمایی موردنظر انجام می‌شود. پس از ثبت درخواست استرداد توسط مسافر، مبلغ جریمه کنسلی و کارمزد انتقال وجه از مبلغ پرداخت ‌شده توسط مسافر کسر می‌شود، لذا لازم به ذکر است که  بازگرداندن پول توسط خط هوایی صورت می گیرد؛ برای پرواز خارجی بین 2 تا 6 هفته و برای پرواز داخلی حداکثر 10 روز کاری زمان بر خواهد بود.</li>
                    <li>آیتورز می‌تواند در استرداد پرواز خارجی به ازای هر بلیط، مبلغی به عنوان سرویس فی ( کارمزد خدمات ) دریافت کند.</li>
                </ul>
            </Panel>
            <Panel header="قوانین هتل" key="11">
                <h3 className='medium-title blue2'>قوانین هتل</h3>
                <ul className='red-bullet-list'>
                    <li>پس از رزرو نهایی هتل امکان اصلاح نام و یا مشخصات مسافر و یا افزودن مسافر به یک اتاق وجود ندارد و اطلاعات و تعداد مسافرین می بایست به صورت صحیح و طبق پاسپورت مسافر وارد شده باشد.</li>
                    <li>پس از رزرو نهایی هتل امکان تغییر تاریخ در رزرو انجام شده وجود ندارد، و می بایست رزرو قبلی مسافر کنسل، جرایم کنسلی اعمال و رزرو جدید انجام شود. </li>
                    <li>چنانچه مسافر به هر دلیل اعم از مثبت شدن تست کرونا، ممنوع الخروج بودن و یا ممانعت مقامات محلی در بدو ورود به مقصد، عدم صدور ویزا از طرف کشور مقصد، یا در مواردی مانند جا ماندن از پرواز، لغو و تغییر پرواز، یا ممانعت ایرلاین از انجام پرواز و یا رعایت نکردن سن قانونی جهت ورود به هتل و یا هر علت دیگری، نتواند از هتل رزرو شده استفاده نماید، آیتورز هیچ مسئولیتی در این موارد نداشته و طبق قوانین کنسلی یا نوشویی هتل اعمال خواهد شد.</li>
                    <li>کنسل کردن رزرو هتل از طریق وب‌ سایت آیتوزر، شامل جریمه‌ی کنسلی می باشد. مهلت و جریمه کنسلی هر هتل به هنگام رزرو مشخص شده. در صورت کنسل کردن واچر هتل، علاوه بر هزینه جریمه مشخص شده، مبلغ 10% از مبلغ کل رزرو (حداقل 200،000 تومان) بابت هزینه خدمات از مبلغ پرداختی مسافر کسر و الباقی به حساب مسافر مسترد می شود. شایان ذکر است اگر رزرو به نام چند مسافر گرفته شده باشد جهت اعلام کنسلی و یا هرگونه پیگیری احتمالی بعدی تنها از طریق نماینده گروه امکان پذیر می باشد.</li>
                    <li>هزینه پرداختی مسافر تنها بابت رزرو اتاق و خدمات قید شده در واچر مسافر می باشد و شامل خدماتی نظیر ترانسفر فرودگاهی، راهنما و ... نمی باشد.</li>
                    <li>در برخی از هتلها در بدو ورود مسافر، اطلاعات کردیت کارت و یا مبلغی به عنوان امانت از مسافر دریافت شده و در صورت استفاده از خدمات غیر رایگان هتل از آن مبلغ کسر می شود.  </li>
                    <li>هزینه های اضافی جهت استفاده خدمات غیر رایگان هتل به عهده مسافر می باشد و جدای هزینه پرداختی مسافر جهت رزرو اتاق است.</li>
                    <li> در شب ژانویه، کریسمس و ایام خاص، گالا دینر و یا بعضی از برنامه ها در هتل شامل هزینه اضافه است که پرداخت هزینه های آن توسط مسافر در هتل اجباری است، لطفا قبل از رزرو هتل از سایت هتل و یا از طریق همکاران آیتورز اطلاعات لازم را کسب نمایید. </li>
                    <li>در  صورت ایجاد خسارت به هتل توسط مسافر، کلیه خسارات از مسافر دریافت خواهد شد.</li>
                    <li>هتلهای برخی از شهرها مبلغی نیز به عنوان مالیات شهری و یا هزینه مجزا بابت تابعیت های خاص از مسافر اخذ میکنند که پرداخت این مبلغ به عهده مسافر می باشد.</li>
                    <li>طبقه و ویوی اتاقهایی که ویو آنها در واچر قید نشده و نوع تخت کاملا بستگی به شرایط موجود بودن در زمان پذیرش مسافر در هتل را دارد. </li>
                    <li>چنانچه در زمان ورود مسافر به هتل برخی از خدمات و امکانات هتل به علت بازسازی، شرایط آب و هوا و ... قابل استفاده نباشد، آیتورز هیچگونه مسئولیتی در این موارد ندارد.</li>
                    <li>پذیرش مسافر در هتل ها (Check In)معمولا ساعت 14:00 و ساعت خروج12:00 ظهر می باشد. در صورتی که مسافر بعد از ساعت 18:00 وارد هتل خواهد شد، میباست به همکاران پشتیبانی آیتورز اطلاع داده تا هتل در جریان ورود دیرهنگام مسافر قرار گرفته و دیر کردن مسافر را فرض بر نوشویی نکند که در این صورت خسارتهای احتمالی به عهده مسافر خواهد بود. در صورت تحویل زودتر اتاق توسط مسافر و استفاده نکردن شبهای رزرو شده اتاق، مبلغی به مسافر مسترد نخواهد شد. </li>
                    <li>در صورتیکه تعداد مسافرین بیشتر از تعداد نفرات قید شده در واچر باشد، در صورت عدم پذیرش مسافرین اضافی و یا دریافت هزینه نفر اضافی از طرف هتل اعم از بزرگسال و کودک آیتورز مسئولیتی در این خصوص ندارد.</li>
                    <li>ورود به هتلها در برخی از شهرها مستلزم دارا بودن بیمه نامه مسافرتی معتبر میباشد که چک کردن این موارد به عنوان مدارک مورد نیاز سفر به عهده مسافر می باشد.</li>
                    <li>با توجه به شرایط فعلی، علی رغم اعلام ملیت از سمت سایت آیتورز، برخی از هتل ها از پذیرش مسافران ایرانی اجتناب می کنند. در صورت بروز این اتفاق، رزرو شما کنسل شده و یا هتلی با شرایط مشابه به شما پیشنهاد خواهد شد.</li>
                    <li>این امکان وجود دارد که بعد از پرداخت وجه از حساب مسافر و در حین خرید ظرفیت هتل مورد نظرتکمیل شود، در این صورت مبلغ بحساب مسافر برگردانده خواهد شد، همچنین ممکن است پس از پرداخت وجه، نیاز به پرداخت مابه التفاوت افزایش نرخ هتل باشد که مسافر می بایست پرداخت نماید. (صرف کسر مبلغ مبنی بر قطعی بودن واچز هتل نیست)</li>
                    <li>جهت اطلاع از هزینه های ابطال رزرواسیون هتل با پشتیبانی آیتورز در ارتباط باشید.</li>
                    <li>توجه داشته باشید شرایط هر هتل در مورد اتاق های 3 تخته هر هتل متفاوت است. توجه به عبارت Triple و extra bed در نام اتاق ضروری است در غیر این صورت هزینه نفر سوم مستقیما از طرف هتل از مسافر دریافت می شود.</li>
                    <li>چنانچه مسافر درخواست برای اتاق هایی با دو تخت یک نفره، تعجیل یا تاخیر در ورود، امکان استعمال دخانیات را داشته باشد، آیتورز صرفا نقش انتقال دهنده درخواست راداد و تایید نهایی بر عهده هتل می باشد. در صورت هزینه بر بودن هر کدام از درخواست ها مسافر موظف به پرداخت آن است.</li>
                    <li>توجه به شرایط سنی افراد در هنگام رزرو هتل ضروری است، غالب هتل های خارجی افراد بالای 4 سال را بزرگسال محسوب می کنند، این قانون در مورد هتل های داخلی 6 سال به بالا هست. در صورت عدم توجه به این مورد مسئولیت آن بر عهده مسافر است</li>
                    <li>در صورت عدم پذیرش مسافر از جانب هتل با واچر معتبر لطفا با پشتیبانی آیتورز در تماس باشید</li>
                    <li>پرداخت عوارض خروج از کشور بر عهده مسافر می باشد</li>
                    <li>پاسخگویی در مورد خدمات هتل بر عهده خود هتل مورد نظر است.</li>
                    <li>رزرو اتاق به منزله موافقت خریدار با شرایط کنسلی می باشد، لذا خواهشمندیم قبل از رزرو به این مهم توجه فرمایید.</li>
                    <li>در برخی از هتلها مخصوصا هتلهای ساحلی، پذیرش کودکان در هتل ممنوع است، مسئولیت کسب اطلاعات در خصوص محدودیت در پذیرش هتلها به عهده مسافر است و قبل از اقدام به رزرو میبایست از سایت هتل اطلاعات لازم توسط مسافر بررسی شود.</li>
                </ul>
            </Panel>
            <Panel header="کنسل کردن/رزرو مجدد" key="2">
                <h3 className='medium-title blue2'>کنسل کردن/رزرو مجدد</h3>
                <ul className='red-bullet-list'>
                    <li>زمانی که پرداخت انجام و بلیط صادر شد، قوانین کنسل کردن یا رزرو مجدد بلیط طبق قوانین خط هوایی است.</li>
                    <li>ایرلاین بازپرداخت را ظرف 2 تا 6 هفته انجام می‌دهد. </li>
                    <li>رزرو مجدد باید حداقل 24 ساعت پیش از تاریخ رفت در رزرو اصلی، انجام شود. (زمانی این رزرو مجدد ثبت‌می‌شود، که هزینه آن کاملا پرداخت شده ‌باشد.)</li>
                    <li>زمانی سفر لغو شده محسوب می‌شود، که آیتورز کنسل شدن آن ‌را تایید کند. </li>
                    <li>اگر پس از ثبت رزرو، مشکلی خارج از اختیارات مجموعه ایجاد شود، آیتورز مختار به لغو رزرو است.</li>
                    <li>برای استرداد وجه مسافر ملزم به ارائه ی شماره کارت و شماره حساب و شبای حساب کارتی است که خرید را از طریق آن انجام داده است.</li>
                    <li>آیتورز اکیداً به مسافران توصیه‌ می‌کند پیش از سفر بیمه مسافرتی تهیه‌کنند. (مسئولیت داشتن این بیمه پیش از سفر بر عهده مسافر است)</li>
                    <li>با استناد به بخشنامه شماره ۹۱۰۶ تاریخ 03/03/1400 سازمان هواپیمایی کشوری در رابطه با تعرفه نظارت بر ایمنی مسافر (I6) در پروازهای داخلی و بین‌المللی، از تاریخ 11/03/140، بلیط پس از صدور، غیرقابل‌استرداد خواهد بود.</li>
                </ul>
            </Panel>
            <Panel header="قوانین بار" key="3">
                <h3 className='medium-title blue2'>قوانین بار</h3>
                <ul className='red-bullet-list'>
                    <li>آیتورز گزینه‌های متنوعی از تامین‌کنندگان مختلف ارائه می‌کند و بنابراین مسئولیت اتفاقاتی که در طول سفر رخ می‌دهند، مانند گم‌شدن بار، اضافه بار، لغو یا تغییر برنامه پرواز را به عهده نمی‌گیرد. مسئولیت این موارد برعهده خطوط هوای است و مسافران باید شکایات خود را به خط هوایی مربوطه ارجاع دهند.</li>
                    <li>مسافر باید دقت کند که بار او مغایرتی با قوانین ایرلاین نداشته ‌باشد. اطلاعات مربوط به بار بر روی وبسایت خط هوایی نوشته‌ شده‌است. </li>
                    <li>لطفا دقت کنید که قوانین بار در خطوط هوایی مختلف ، از یکدیگر متفاوت می باشد، لطفا برای کسب اطلاعات بیشتر در مورد بار اضافی و هزینه آن با خط هوایی تماس بگیرید. (این اطلاعات معمولا در وبسایت خطوط هوایی موجود است.)</li>
                    <li><b>بلیط های نوزاد در پروازهای داخلی هواپیمایی ماهان فاقد هرگونه بار مجاز می باشد.</b></li>
                    <li>
                        <p>توجه به رده سنی مسافران در هنگام ثبت اطلاعات بسیار مهم بوده و در صورت درج اطلاعات نادرست از ورود مسافر به هواپیما جلوگیری خواهد شد که مسئولیت آن بر عهده خود مسافر می باشد)</p>
                        <ul className='no-list-style'>
                            <li>رده سنی نوزاد = 10 روز تا دو سال</li>
                            <li>رده سنی کودک = بین 2 الی 12 سال تمام</li>
                            <li>رده سنی بزرگسال = بیش از 12 سال</li>
                        </ul>
                    </li>
                </ul>
                <h4 className='normal-title blue2'>بار ویژه</h4>
                <ul className='red-bullet-list'>
                    <li>در بسیاری از ایرلاین ها از مسافر خواسته می شود تا در صورت داشتن بار با شرایطی خاص، اطلاعات مورد نظر این بار را با خط هوایی مورد نظر به اشتراک بگذارد؛ لازم است که ابعاد (طول، عرض و ارتفاع) و و وزن بسته را اعلام کنید. </li>
                    <li>معمولا خط هوایی پس از چند ساعت جواب ایمیل شما را ارسال می‌کند. </li>
                    <li>هزینه این قبیل بارها به خود ایرلاین پرداخت می‌شود. </li>
                    <li>بارهای ویژه شامل مواردی مثل لوازم اسکی، گلف و آلات موسیقی هستند. </li>
                    <li>لطفا دقت داشته‌باشید که نمی‌توان بارهای بزرگ را در هواپیماهای کوچک جای داد. </li>
                    <li>برای بارهای ویژه، آیتورز، فقط می‌تواند درخواستی برای خط هوایی ارسال نماید؛ تا زمانی که خط هوایی، تاییدیه را صادر نکرده است، درخواست پذیرفته شده محسوب نمی شود.</li>
                </ul>
                
                <h4 className='normal-title blue2'>محتوای کیف دستی</h4>
                <ul className='red-bullet-list'>
                    <li>برای کسب اطلاعات در مورد به همراه‌‌داشتن مایعات، دارو، غذای کودک و غذاهای رژیمی خاص باید به وبسایت خط هوایی مراجعه‌کنید.</li>
                </ul>
            </Panel>
            <Panel header="سفر با کودکان" key="4">
                <h3 className='medium-title blue2'>سفر با کودکان</h3>
                <ul className='red-bullet-list'>
                    <li>امکان تهیه ی بلیط نوزاد یا کودک به تنهایی و جدا از بزرگسال به هیچ وجه ممکن نمی باشد.</li>
                    <li>وارد کردن سن دقیق کودکانی که در سفر همراه شما هستند، بسیار حائز اهمیت است. </li>
                    <li>سن کودکان توسط خط هوایی بررسی می‌شود. </li>
                    <li>دقت کنید که سن کودک در تاریخ برگشت تغییر نکرده باشد.</li>
                    <li> اگر سن کودک شما در طول سفر به دو سال تغییر می‌کند، در رزرو سن او را بالای دو سال ثبت کنید. </li>
                    <li>اگر سن کودک در سفر برگشت به بالای 12 سال تغییر کند، رزرو را با عنوان بزرگسال ثبت کنید. </li>
                    <li>کودکان زیر دو سال به عنوان نوزاد ثبت می‌شوند؛ این امر بدین معناست که آنها نیازی به صندلی ندارند. </li>
                    <li>خرید بلیط برای کودکی که هنوز به دنیا نیامده‌، امکان‌پذیر نمی باشد، چون در واقع خرید بلیط بدون ارائه نام و سایر اطلاعات امکان‌پذیر نیست.</li>
                    <li> آیتورز هیچ مسئولیتی در قبال پذیرش بلیط کودک،که بعد از فرایند خرید اضافه شده است، نخواهد داشت. </li>
                    <li>خط هوایی بودن جای اضافی برای نوزاد را تضمین نمی‌کند و از نظر فنی، گرفتن صندلی برای نوزاد، امکان‌پذیر نیست و نوزاد بر روی پای سرپرست خود خواهد نشست. </li>
                    <li>نمی توان بلیط نوزاد را در میانۀ برنامه سفر به بلیط خریداری شده اضافه کرد.</li>
                    <li>برای مثال اگر والدینی قصد دارند به سفر رفته و نوزاد خود را در کشوری دیگر به دنیا بی آورند (و برای سفر خود بلیط برگشت تهیه کرده اند) نمی توانند نوزاد متولد شده را به بلیط برگشت خود اضافه کنند. </li>
                    <li>همچنین نمی توان سفارش یک بلیط جداگانه برای نوزاد ثبت شود، بلکه باید بلیطی جدید برای یک بزرگسال و یک نوزاد صادر شود. </li>
                </ul>
                <h3 className='normal-title blue2'>سفر کودک بدون همراه</h3>
                <ul className='red-bullet-list'>
                    <li> کودکانی که به تنهایی به مسافرت می پردازند، از قبل باید سرویس کودک بدون همراه رزرو گردیده باشد.  هزینه این سرویس، و همچنین حداقل سن کودک، در هر خط هوایی، با یکدیگر متفاوت است.</li>
                    <li> استفاده از این سرویس تنها باید از یک خط هوایی بلیط تهیه شده، و ضمناً، نام، آدرس و تلفن فردی که در فرودگاه به استقبال کودک خواهد آمد در اطلاعات مورد نیاز ثبت شده و در اختیار آیتورز قرار گیرد.</li>
                    <li> درخواست مورد نظر از طرف آیتورز به ایرلاین مربوطه فرستاده شده ، و تا زمان تایید نهایی خط هوایی این درخواست رزرو قطعی محسوب نخواهد شد.</li>
                </ul>
            </Panel>
            <Panel header="شرایط ویژه" key="5">
                <h3 className='medium-title blue2'>شرایط ویژه</h3>
                <ul className='red-bullet-list'>
                    <li>خریداران محترم توجه فرمایید، برای سفر به بعضی مناطق و کشورها باید واکسن های خاصی را استفاده نمایید.</li>
                    <li>افرادی که مبتلا به بیماری هستند و یا از داروهای تضعیف کننده سیستم ایمنی استفاده میکنن، جهت ورود به هواپیما ملزم به تهیه مجوز پزشکی، از پزشک معتمد ایرلاین هستند. (مسافر موظف است در این زمینه مستقیما با ایرلاین در ارتباط باشد، در غیر این صورت مسئولیت عواقب احتمالی آن بر عهده مسافر است) همچنین توصیه می شود داروهای مورد نیاز در طول سفر همراه مسافر باشد</li>
                    <li>لازم به ذکر است چنانچه ایرلاین علی رقم وجود مجوز پزشکی تشخیص دهد سفر برای مسافر خطر آفرین است، می تواند از پرواز فرد ممانعت نماید و کنسلی بلیط در چنین شرایطی مطابق قوانین اولیه بلیط می باشد وآیتورز هیچ گونه مسئولیتی در این زمینه ندارد.</li>
                    <li>در صورت نیاز به استفاده از خدمات خاص مثل (ویلچر، ماسک اکسیژن در حین پرواز و ...) باید با واحد پشتیبانی آیتورز در ارتباط باشید.</li>
                    <li>توجه داشته باشید صرف ارائه درخواست به پشتیبانی آیتورز، منزله تایید نیست و باید هماهنگی و بررسی لازم با ایرلاین در این زمینه صورت گیرد.</li>
                </ul>
            </Panel>
            <Panel header="شرایط پذیرش حیوان خانگی در پرواز" key="6">
                <h3 className='medium-title blue2'>شرایط پذیرش حیوان خانگی در پرواز</h3>
                <ul className='red-bullet-list'>
                    <li>شرایط حمل حیوان خانگی با توجه به  قوانین هر کشور و ضوابط ایرلاین های مختلف متفاوت است.</li>
                    <li>امکان ورود حیوان خانگی به داخل کابین در ایرلاین های ایرانی میسر نمی باشد و صرفا در قسمت بار امکان پذیر است،  این ضوابط در مورد هر ایرلاین متفاوت بوده و لازم است خود مسافر از طریق سایت ایرلاین مورد نظر ضوابط را بررسی نماید.</li>
                    <li>در ایرلاین های خارجی نیز شرایط و ضوابط هر ایرلاین برای ورود حیوان خانگی به قسمت کابین و یا بار متفاوت بوده که توصیه می شود مسافران قبل خرید بلیط از سایت ایرلاین این مورد را بررسی نمایند.</li>
                    <li>جهت حمل حیوان خانگی، به همراه داشتن مدارک کامل از جمله شناسنامه و گواهی بهداشت حیوان خانگی ضروری است.</li>
                    <li>با توجه به ظرفیت محدود برای حمل حیوان خانگی، ممکن است درخواست شما از طرف ایرلاین رد شود و این مساله ارتباطی با آیتورز ندارد.</li>
                </ul>
                <h4 className='normal-title blue2'>آنچه که باید درباره مهاجرت با حیوان خانگی بدانید</h4>
                <p>با توجه به وجود مراحل متعدد و متفاوت هر کشور در زمینه سفر به همراه حیوان خانگی، که بعضاً ممکن است زمانی برابر سه ماه الی یکسال طول بکشد. خواهشمند است با زمانبندی مناسب به طی کردن هر یک از مراحل بپردازید، عواقب عدم توجه به این موضوع بر عهده مسافر می باشد.</p>
            </Panel>
            <Panel header="بلیط و پرواز" key="7">
                <h3 className='medium-title blue2'>بلیط و پرواز</h3>
                <ul className='red-bullet-list'>
                    <li>در ازای هر مسافر یک شماره بلیط صادر شده و مسئولیت اطمینان از این موضوع برعهدۀ خود مسافر می باشد، این امر بدین معنا است که برای یک مسیر تک سفره، هر مسافر باید دارای یک شماره بلیط بوده، و در غیراین صورت، مسافر باید در اسرع وقت با آیتورز تماس حاصل نماید.</li>
                    <li>اگر به صورت گروهی سفر می کنید، به یاد داشته باشید که تمامی مسافران شماره بلیط (هر نفر یک شماره) را باید دریافت کرده باشند.</li>
                    <li>آیتورز بلیط رفت و برگشت را نیز به صورت دو بلیط مجزا ارائه می‌کند؛ قوانین در مورد هر دو بلیط اعمال می‌شود. </li>
                    <li>پرواز غیرمستقیم پروازی است که در چندجا توقف دارد و هواپیما و شماره پرواز در حین سفر تغییر می‌کند. </li>
                    <li>اگر در پرواز غیرمستقیم مسافر مجبور به تغییر فرودگاه شود، هزینه جابه‌جایی بین دو فرودگاه برعهده مسافر است.</li>
                    <li>زمانی که فرودگاه تغییر می‌کند، مسافر ملزم به تحویل گیری بار خود، و تحویل آن به خدمۀ پرواز بعدی می باشد.</li>
                    <li> گاهی مسافر برای پرواز و حرکت هواپیمای بعدی یک شب باید صبر نماید؛ هزینۀ اقامت در این مدت برعهدۀ خود مسافر است.</li>
                    <li>اگر پرواز اول در سفری چند مسیره تاخیر داشته‌ و مسافر دیر به پرواز بعدی برسد، مسئولیت رساندن مسافر به مقصد برعهده خط هوایی است، اما اگر بلیط‌ها به صورت جداگانه خریداری شده‌باشند، خط هوایی مسئولیتی در قبال جاماندن مسافر از پرواز، به دلیل تاخیر اول نخواهد داشت. بنابراین مسافر باید اطمینان حاصل کند که برای رسیدن به پرواز بعدی زمان کافی را در اختیار دارد و  مسئولیت بررسی زمان پروازها پیش از سفارش برعهده وی است.</li>
                    <li>تا زمانی که شماره پرواز تغییر پیدا نکند، اگر هواپیمایی برای گرفتن سوخت، به زمین بشیند، باز هم پرواز مستقیم محسوب خواهد شد.</li>
                    <li>این قبیل توقف‌ها طبق قوانین یاتا قابل قبول است و پروازی بدون توقف محسوب می‌شود، که از نقطه A مستقیما به نقطه B می رود.</li>
                    <li>اطلاع رسانی جهت نهایی شدن سفارش و اعلام تغییرات در پروازهای داخلی از طریق پیامک و در پروازهای خارجی از طریق ایمیل و پیامک به مسافر صورت خواهد گرفت، لذا صحت شماره وارده و اطمینان از در دسترس بودن شماره بر عهده مسافر می باشد.</li>
                    <li>از زمان ثبت رزرو تا تاریخ رفت، لطفا مرتباً پیامک و ایمیل خود را چک کرده زیرا کلیه ارتباطات آیتورز و مسافران بعد از انجام رزرو از این طریق این راه های ارتباطی می باشد.</li>
                    <li>خواهشمندیم پس از نهایی شدن خرید از دریافت پیامک و ایمیل از جانب آیتورز اطمینان حاصل نمایید و در غیر این صورت در اسرع وقت با پشتیبانی آیتورز تماس حاصل فرمایید و از خرید مجدد خود داری کنید. (خط هوایی بدون دادن اخطار، دو رزرو یکسان را کنسل خواهدکرد) در این صورت آیتورز مسئولیتی در قبال کنسلی خطوط هوایی یا بازگرداندن هزینه بلیط برعهده نخواهد داشت.</li>
                    <li>مدت زمان ارسال بلیط 24 ساعت بوده و بعد از تکمیل فرایند خرید به ایمیل مسافر فرستاده می شود.</li>
                    <li>مسئولیت اطلاع از زمان درست پرواز رفت و برگشت برعهده مسافر است. </li>
                    <li>تمامی زمان‌های قید شده در بلیط‌ها به وقت محلی فرودگاه‌هاست. </li>
                    <li>زمان توقف هواپیما در بین راه که در بلیط قید شده‌است، مطابق با زمان محلی است. </li>
                    <li>برای انجام پرداخت، می‌توانید از طریق درگاه آنلاین اقدام نمایید و لازم است در زمان سفارش کل مبلغ را پرداخت نمایید. </li>
                    <li>پس از ثبت سفارش، زمانی رزرو شما قطعی خواهد شد که هزینه آن را پرداخت کنید. </li>
                    <li>تنها رزروهایی قطعی خواهند بود که هزینه ی آنها، به طور کامل پرداخت گردیده باشد. </li>
                    <li>مبلغ پرداخت شده برای بلیط شامل هزینه حمل و نقل و یا اقامت در بین مسیرها نیست. </li>
                    <li>واریز وجه به صورت علی الحساب، عدم اطلاع و ثبت فیش بانکی بدون مشخصات، اختلال در سیستم پرداخت آنلاین؛ بدین معنا که مبلغ مورد نظر به حساب بانکی آیتورز واریز نشده باشد؛ رزرو قطعی محسوب نشده و آیتورز هیچگونه مسئولیت درقبال موارد فوق الذکر نخواهد داشت. </li>
                    <li>در صورت پرداخت وجه رزرو به صورت غیرآنلاین، به دلیل مشخص نبودن اطلاعات واریزکننده، مسافر می بایست تصویر فیش واریزی را به ایمیل بخش پشتیبانی ارسال و جهت صدور بلیط با همان بخش هماهنگ نماید .  </li>
                    <li>زمانی‌که سفارش خود را ثبت و هزینه آن ‌را پرداخت کردید، تاییدیه سفارش به آدرس ایمیلی که در زمان رزرو ثبت کرده‌اید، ارسال خواهدشد، در این ایمیل لینکی به صفحه رزرو شخصی شما وجود دارد که از طریق آن می‌توانید، بلیط خود را به صورت الکترونیکی چاپ کنید. (بلیط سفر شما الکترونیکی است و دیگر بلیط کاغذی به شما داده‌نخواهد شد)</li>
                    <li>توصیه می‌کنیم که پیش از سفر بلیط خود را چاپ نمایید. (در این بلیط اطلاعات اصلی مثل شماره بلیط و زمان پرواز نوشته‌شده‌است)</li>
                    <li>در پروازهای چارتری ارائهی یک نسخه چاپ شده از بلیط و در پروازهای سیستمی، ارائه شماره بلیط به همراه مدرک معتبر شناسایی الزامی است.</li>
                    <li>برای تغییر دادن یا افزودن اطلاعات به سفارش خود، در زمان تماس با آیتورز، باید شماره رزرو را ارائه کنید.</li>
                    <li>لازم به ذکر است، قیمت بلیط بزرگسال، کودک و نوزاد در بلیط های سیستمی (بلیط های که تابع قانون هواپیمایی کشوری می باشند و با نرخ های شناور به فروش می رسد) متفاوت می باشد، در حالیکه در بلیط های چارتری(بلیطی هایی که نرخ های آن توسط آژانس های چارتر کننده تعیین میشود و استرداد آن تابع قوانین سازمان هواپیمایی کشوری نمی باشد)  به این صورت نیست.</li>
                    <li>قیمت اعلام شده بلیط از جانب آیتورز و مفاد آن رسمی بوده و خریدار حق هیچ گونه دخل و تصرفی در آن ندارد.</li>
                    <li>
                        از مسافرین گرامی خواهشمندیم به موقع در فرودگاه و ترمینال درج شده در بلیط حاضر باشند:
                        <ul className='margin-top-light'>
                            <li>پروازهای داخلی یک ساعت قبل از زمان پرواز </li>
                            <li>پروازهای خارجی حداقل چهار ساعت قبل از زمان پرواز</li>
                        </ul>
                    </li>
                    <li>
                        پیش از سفر ترمینال فرودگاه خود را با ایرلاین مربوطه چک نمایید.
                        <ul className='margin-top-light'>
                            <li>ترمینال 1: کیش‌ایر، زاگرس، وارش</li>
                            <li>ترمینال 2: ایران ایر، ایر تور، آتا، قشم ایر، معراج، کارون</li>
                            <li>ترمینال 4: ماهان، کاسپین، آسمان، اترک، تابان، سپاهان، فلای پرشیا</li>
                            <li>ترمینال 4 یا 6 : سپهران، پویا، ساها (قبل از سفر، از اطلاعات فرودگاه مهرآباد استعلام شود)</li>
                        </ul>
                    </li>
                    <li>در صورت عدم استفاده از پرواز رفت در پرواز های خارجی سیستمی، بلیط برگشت توسط ایرلاین باطل می گردد.</li>
                </ul>
            </Panel>
            <Panel header="قوانین پرواز داخلی" key="8">
                <h3 className='medium-title blue2'>قوانین پرواز داخلی</h3>
                <p>چنانچه پرواز لغو شود و یا بعلت تاخیر بالای دو ساعت مسافر درخواست انصراف داشته باشد، باید بلیط و یا رسید خود را نزد کانتر هواپیمایی مهر زده . بر اساس قوانین شرکت هواپیمایی مربوطه، نسخه مورد تایید را به محل شرکت ارسال نماید، هزینه پرواز پس از تایید ایرلاین مربوطه به مسافر بازگردانده خواهد شد. چنانچه مسافر پیش از رفتن به فرودگاه از لغو پرواز مطلع شود، با تماس با واحد پشتیبانی آیتورز می تواند بدون پرداخت جریمه بلیط خود را کنسل نماید و پرواز جایگزین تهیه کند، لازم به ذکر است چنانچه بلیط رفت و برگشت با دو ایرلاین متفاوت باشد، در صورت لغو پرواز و یا انصراف باید اصل بلیط مهر شده را به دفتر آیتورز ارسال نماید، ممکن است بدلیل قوانین بین ایرلاین ها لغو پرواز برگشت شامل جریمه کنسل شود.</p>
            </Panel>
            <Panel header="قوانین کنسلی بلیط رفت و برگشت داخلی" key="9">
                <h3 className='medium-title blue2'>قوانین کنسلی بلیط رفت و برگشت داخلی</h3>
                <p>
                چنانچه بلیط پرواز به صورت دو طرفه (رفت و برگشت) خریداری گردد، در صورتی که یک مسیر پروازی توسط ایرلاین لغو شود و یا با تاخیر و تعجیل بیش از دو ساعت همراه باشد و مسافر از پرواز انصراف دهد، قوانین درج شده در پایین جهت کنسل کردن مسیر دیگر صدق می کند. 
                </p>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center">
                        <thead>
                            <tr>
                                <th>ایرلاین</th>
                                <th>فاصله پرواز رفت و برگشت</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>ایران ایر</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={iranAirLogo} alt="ایران ایر" />
                                        </span>
                                    </span>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>کاسپین</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={caspianLogo} alt="کاسپین" />
                                        </span>
                                    </span>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>آتا</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={ataLogo} alt="آتا" />
                                        </span>
                                    </span>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>آسمان</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={asemanLogo} alt="آسمان" />
                                        </span>
                                    </span>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>ماهان</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={mahanLogo} alt="ماهان" />
                                        </span>
                                    </span>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>پارس ایر</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={parsAirLogo} alt="پارس ایر" />
                                        </span>
                                    </span>                                    
                                </td>
                                <td className='bold'>
                                    کمتر از 3 ساعت
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>کارون</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={karonLogo} alt="کارون" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>قشم ایر</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={gheshmAirLogo} alt="قشم ایر" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>کیش ایر</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={kishAirLogo} alt="کیش ایر" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>سپهران</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={sepehranLogo} alt="سپهران" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>ساها</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={sahaLogo} alt="ساها" />
                                        </span>
                                    </span> 
                                </td>
                                <td className='bold'>
                                    کمتر از 48 ساعت
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>معراج</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={meragLogo} alt="معراج" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>تابان</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={tabanLogo} alt="تابان" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>وارش</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={vareshLogo} alt="وارش" />
                                        </span>
                                    </span>  
                                </td>
                                <td className='bold'>
                                    کمتر از 24 ساعت
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} className="bg-gray bold">
                                    در صورت لغو یا تاخیر و تعجیل بیش از 2 ساعت (با فاصله زمانی ذکر شده) پرواز رفت یا برگشت بدون جریمه استرداد خواهد شد.
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>زاگرس</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={zagrosLogo} alt="زاگرس" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>ایران ایر تور</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={iranAirTourLogo} alt="ایران ایر تور" />
                                        </span>
                                    </span>                                      
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} className="bg-gray bold">
                                در صورت لغو یا تاخیر و تعجیل بیش از 2 ساعت پرواز رفت،  پرواز برگشت با جریمه استرداد خواهد شد.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <h4 className='normal-title blue2'>درصد جریمه استرداد پرواز داخلی</h4>
                {airlinesCancellationTables}
                <h4 className='normal-title blue2'>بار مجاز پرواز داخلی</h4>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={3}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={iranAirLogo} alt="ایران ایر" />
                                        <span className='airline-title'>ایران ایر</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>Y S V Q K M N X L O</td>
                                <td>20 کیلوگرم </td>
                                <td rowSpan={2}>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>C J I</td>
                                <td>30 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={4}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={asemanLogo} alt="آسمان" />
                                        <span className='airline-title'>آسمان</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>U R X Q N M L K H B O E</td>
                                <td>20 کیلوگرم </td>
                                <td rowSpan={3}>0 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>Y V S</td>
                                <td>25 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>D I Z</td>
                                <td>30 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={3}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={mahanLogo} alt="ماهان" />
                                        <span className='airline-title'>ماهان</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>N T V L Q U W WW WA WS WQ X B BH S Y</td>
                                <td>20 کیلوگرم </td>
                                <td rowSpan={2}>0 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>P C CC I R</td>
                                <td>25 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={2}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={ataLogo} alt="آتا" />
                                        <span className='airline-title'>آتا</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>Y R O V B Q P N M L A D K W H F E S I</td>
                                <td>20 کیلوگرم </td>
                                <td>10 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={4}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={tabanLogo} alt="تابان" />
                                        <span className='airline-title'>تابان</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>Y YD YF YK YN YP</td>
                                <td rowSpan={3}>20 کیلوگرم </td>
                                <td rowSpan={3}>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>T R V Z L M A I P F C K D N</td>
                            </tr>
                            <tr>
                                <td>B W J S O Q E U H X G</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={17}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={gheshmAirLogo} alt="قشم ایر" />
                                        <span className='airline-title'>قشم ایر</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>A Y V M K N Q R</td>
                                <td>20 کیلوگرم </td>
                                <td>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>J JF JB JH JD</td>
                                <td rowSpan={15}>20 کیلوگرم</td>
                                <td rowSpan={15}>0 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>Z ZF ZB ZH ZD</td>
                            </tr>
                            <tr>
                                <td>Q QF QB QH QD</td>
                            </tr>
                            <tr>
                                <td>W WF WB</td>
                            </tr>
                            <tr>
                                <td>N NF NB</td>
                            </tr>
                            <tr>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>S SF</td>
                            </tr>
                            <tr>
                                <td>H HF</td>
                            </tr>
                            <tr>
                                <td>P PF PB</td>
                            </tr>
                            <tr>
                                <td>R RF RB RH RD RE</td>
                            </tr>
                            <tr>
                                <td>M</td>
                            </tr>
                            <tr>
                                <td>K KF</td>
                            </tr>
                            <tr>
                                <td>L LF LB LH</td>
                            </tr>
                            <tr>
                                <td>I IF IB IH IE</td>
                            </tr>
                            <tr>
                                <td>V A F U D</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={2}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={karonLogo} alt="کارون" />
                                        <span className='airline-title'>کارون</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>B S Y N Q K V X H L M R U D O E Z F P</td>
                                <td>20 کیلوگرم </td>
                                <td>0 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={3}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={kishAirLogo} alt="کیش ایر" />
                                        <span className='airline-title'>کیش ایر</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>J Y V R D A H B P Q M N L O E K U X S</td>
                                <td>20 کیلوگرم </td>
                                <td>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>C</td>
                                <td>30 کیلوگرم </td>
                                <td>0 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={20}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={zagrosLogo} alt="زاگرس" />
                                        <span className='airline-title'>زاگرس</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>C J (CIP)</td>
                                <td rowSpan={15}>20 کیلوگرم </td>
                                <td rowSpan={19}>0 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>Z ZD ZF ZI ZL</td>
                            </tr>
                            <tr>
                                <td>F FD FF FI FL FN FQ FS FU FW</td>
                            </tr>
                            <tr>
                                <td>I ID IF</td>
                            </tr>
                            <tr>
                                <td>T TD TF</td>
                            </tr>
                            <tr>
                                <td>U UD UF</td>
                            </tr>
                            <tr>
                                <td>A AD AF</td>
                            </tr>
                            <tr>
                                <td>B BD BF BI D DD</td>
                            </tr>
                            <tr>
                                <td>S</td>
                            </tr>
                            <tr>
                                <td>M MD</td>
                            </tr>
                            <tr>
                                <td>N ND</td>
                            </tr>
                            <tr>
                                <td>R RD RF</td>
                            </tr>
                            <tr>
                                <td>H HD</td>
                            </tr>
                            <tr>
                                <td>Q QD</td>
                            </tr>
                            <tr>
                                <td>L LD</td>
                            </tr>
                            <tr>
                                <td>P PD</td>
                                <td rowSpan={4}>15 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>V VD VF</td>
                            </tr>
                            <tr>
                                <td>Y YD K KD</td>
                            </tr>
                            <tr>
                                <td>W WD WF</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={4}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={meragLogo} alt="معراج" />
                                        <span className='airline-title'>معراج</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>P P1 Y Y1 Y2 Y3 B B1 B2 B3</td>
                                <td rowSpan={3}>20 کیلوگرم </td>
                                <td rowSpan={3}>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>M M1 M2 M3</td>
                            </tr>
                            <tr>
                                <td>H H1 H2 H3 H4 H5 H6</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={2}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={sepehranLogo} alt="سپهران" />
                                        <span className='airline-title'>سپهران</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>تمامی شناسه‌های نرخی</td>
                                <td>20 کیلوگرم </td>
                                <td>10 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={2}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={parsAirLogo} alt="پارس ایر" />
                                        <span className='airline-title'>پارس ایر</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>خروجی از قشم</td>
                                <td>20 کیلوگرم </td>
                                <td>10 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <p>در پروازهای خروجی از قشم ایربعلت محدودیت حجم بار، هر مسافر امکان حمل یک چمدان بسته باری متوسط (طول و عرض بین 58 تا 68 سانت و ارتفاع بین 61 تا 68 سانتی متر ) را دارد.</p>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={2}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={puyaLogo} alt="پویا" />
                                        <span className='airline-title'>پویا</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>تمامی شناسه‌های نرخی</td>
                                <td>15 کیلوگرم </td>
                                <td>0 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={7}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={vareshLogo} alt="وارش" />
                                        <span className='airline-title'>وارش</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>M V U P S R</td>
                                <td rowSpan={6}>20 کیلوگرم </td>
                                <td rowSpan={6}>0 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>MI VI UI PI SI RI WI LI KI QI YI NI HI</td>
                            </tr>
                            <tr>
                                <td>W L K Q Y N H I J</td>
                            </tr>
                            <tr>
                                <td>MN VN PN RN LN KN QN YN HN IN</td>
                            </tr>
                            <tr>
                                <td>UU VV MM LL KK QQ WW PP SS RR</td>
                            </tr>
                            <tr>
                                <td>HH II NN</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={5}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={iranAirTourLogo} alt="ایران ایر تور" />
                                        <span className='airline-title'>ایران ایر تور</span>
                                    </span>
                                </td>
                                <td className='th width-400'>بازه قیمتی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>500,000  تومان</td>
                                <td rowSpan={4}>20 کیلوگرم </td>
                                <td rowSpan={4}>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>300,000  الی 499,000 تومان</td>
                            </tr>
                            <tr>
                                <td>155,000  الی 299,000 تومان</td>
                            </tr>
                            <tr>
                                <td>154,000  تومان و پایین تر</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                {/* <p>
                در پروازهای خروجی از قشم ایربعلت محدودیت حجم بار، هر مسافر امکان حمل یک چمدان بسته باری متوسط (طول و عرض بین 58 تا 68 سانت و ارتفاع بین 61 تا 68 سانتی متر ) را دارد.
                </p> */}
            </Panel>
            <Panel header="قوانین پرواز خارجی" key="10">
                <h3 className='medium-title blue2'>قوانین پرواز خارجی</h3>
                <ul className='red-bullet-list'>
                    <li>کاربر گرامی توجه داشته باشید کسر مبلغ از حساب شما به منزله قطعی بودن صدور بلیط نمی باشد، ممکن است در حین خرید بلیط پرواز خارجی بعلت تکمیل ظرفیت پرواز، پول از حساب شما کسر شود ولی بلیطی صارد نشود. نگران نباشید مبلغ کسر شده بحساب شما برگشت داده خواهد شد</li>
                    <li>اگر بلیط شما چندمسیره است، لطفا در نظر داشته باشید که باید بلیط پروازها دقیقا مطابق با برنامه سفر استفاده شوند، اگر مسافر آگاهانه یا ناآگاهانه، پروازی را از دست بدهد، خط هوایی سایر بلیط‌های سفر را بدون بازپرداخت هزینه، لغو خواهد کرد. </li>
                    <li>در کانتر پذیرش، زمانی‌که گذرنامه معتبر خود را نشان می‌دهید، به شما کارت پرواز داده ‌خواهد شد. </li>
                    <li>هم‌چنین می‌توانید کارت پرواز را به صورت آنلاین دریافت کنید. </li>
                    <li>بر طبق قوانین برخی از شرکت های هواپیمایی خارجی، ثبت حضور آنلاین یا همان Online check inدر وبسایت هواپیمایی یک ضرورت بوده ودر غیر این صورت کارت پرواز با مبلغی به عنوان جریمه صادر می شود و مسئولیت عواقب آن با مسافر است.</li>
                    <li>تمامی امور در ارتباط با ویزا و ویزای ترانزیت و اقامت بر عهده مسافر بوده و آیتورز مسئولیتی در این زمینه ندارد.</li>
                    <li>مسافر موظف است قبل از اقدام به خرید بلیط، تاریخ بلیط را با تاریخ ویزا مطابقت دهد. چنانچه اعتبار پاسپورت کمتر از 6 ماه (180 روز کامل) باشد اجازه خروج از کشور در فرودگاه به مسافر داده نمی شود و مسئولیت آن بر عهده مسافر می باشد.</li>
                    <li>در صورت تمدید پاسپورت بعد از خرید بلیط، حتما تصویر پاسپورت جدید را به مرکز پشتیبانی Online@itours.ir ایمیل کنید. در نظر داشته باشید بر طبق قوانین هواپیمایی، ممکن است به روز کردن پاسپورت شامل هزینه گردد که مسافر باید آن را پرداخت نماید</li>
                    <li>توقف پرواز در برخی کشورها نیازمند ویزای ترانزیت و یا گذرنامه معتبر جهت تردد می باشد؛ در صورت نقص مدارک مسافر از پرواز وی جلوگیری خواهد شد، مسافر موظف است پیش از خرید این موارد را با پشتیبانی آیتورز چک نماید .</li>
                </ul>
            </Panel>
            <Panel header="قوانین پکیج" key="12">
                {/* <h3 className='medium-title blue2'>قوانین پکیج</h3> */}
                <h3 className='medium-title blue2'>شرایط و ضوابط عمومی رزرو و خرید بلیط هواپیما</h3>
                <ul className='red-bullet-list'>
                    <li>خرید اینترنتی بلیط، به منزله آگاهی از قوانین و مقررات خرید بلیط از جانب خریدار می باشد.</li>
                    <li>با توجه به اینترنتی بودن روند فروش بلیط هواپیما در سایت آیتورز، در صورت بروز شرایط کنسلی، تاخیر یا تعجیل پرواز و یا شرایط اضطراری یا اعلام ورشکستگی ایرلاین ، شرکت هواپیمایی برگزار کننده پرواز مسئول می باشد و آیتورز مسئولیتی در این زمینه ندارد.</li>
                    <li>خریدار موظف است در هنگام ثبت نام و رزرو بلیط، اطلاعات مسافر (نام و نام خانوادگی، مسیر پرواز، ساعت پرواز، تاریخ سفر) را به درستی درج نماید، نام مسافر به صورت انگلیسی و فارسی ثبت می شود و باید دقیقا بر اساس گذرنامه وارد شود و امکان اصلاح نام پس از نهایی شدن خرید وجود ندارد، مسئولیت بروز هر گونه خطا در این زمینه با مسافر می باشد .</li>
                    <li>در صورت اشتباه وارد کردن مشخصات باید بلیط را با جریمه کنسل و مجددا خریداری نمایید.</li>
                    <li>به هیچ عنوان نام خود را به صورت خلاصه و یا مخفف ذکر نکنید. </li>
                    <li>طبق قانون کشورهای اروپایی در برخی از موارد، خط هوایی خسارت تاخیر، کنسل شدن، و جلوگیری از سوارشدن مسافر به هواپیما را پرداخت یا کل پول را باز میگرداند. این اطلاعات معمولا در فرودگاه‌های اروپایی به مسافران داده شده، و یا در وبسایت خط هوایی وجود دارد. جبران این خسارت برعهده خط هوایی است.</li>
                    <li>در گونه تغییر در نوع هواپیما بر عهده شرکت هواپیمایی برگزارکننده پرواز است و آیتورز مسئولیتی در این زمینه ندارد.</li>
                    <li>اگر مسافر تغییر در زمان پرواز را نپذیرد، باید به آیتورز اطلاع دهد. </li>
                    <li>بر اساس قوانین امکان انتقال بلیط خریداری شده به فرد دیگر وجود ندارد، عدم تطابق نام مسافر در بلیط و کارت شناسایی، ممانعت پرواز مسافر از جانب فرودگاه را به همراه خواهد داشت.</li>
                    <li>کنسلی و استرداد بلیط‌های سیستمی داخلی و خارجی به درخواست مسافر، مطابق قوانین مندرج در بلیط و اعلام‌شده از طرف شرکت هواپیمایی موردنظر انجام می‌شود. پس از ثبت درخواست استرداد توسط مسافر، مبلغ جریمه کنسلی و کارمزد انتقال وجه از مبلغ پرداخت ‌شده توسط مسافر کسر می‌شود، لذا لازم به ذکر است که  بازگرداندن پول توسط خط هوایی صورت می گیرد؛ برای پرواز خارجی بین 2 تا 6 هفته و برای پرواز داخلی حداکثر 10 روز کاری زمان بر خواهد بود.</li>
                    <li>آیتورز می‌تواند در استرداد پرواز خارجی به ازای هر بلیط، مبلغی به عنوان سرویس فی ( کارمزد خدمات ) دریافت کند.</li>
                </ul>

                <h3 className='medium-title blue2'>کنسل کردن/رزرو مجدد</h3>
                <ul className='red-bullet-list'>
                    <li>زمانی که پرداخت انجام و بلیط صادر شد، قوانین کنسل کردن یا رزرو مجدد بلیط طبق قوانین خط هوایی است.</li>
                    <li>ایرلاین بازپرداخت را ظرف 2 تا 6 هفته انجام می‌دهد. </li>
                    <li>رزرو مجدد باید حداقل 24 ساعت پیش از تاریخ رفت در رزرو اصلی، انجام شود. (زمانی این رزرو مجدد ثبت‌می‌شود، که هزینه آن کاملا پرداخت شده ‌باشد.)</li>
                    <li>زمانی سفر لغو شده محسوب می‌شود، که آیتورز کنسل شدن آن ‌را تایید کند. </li>
                    <li>اگر پس از ثبت رزرو، مشکلی خارج از اختیارات مجموعه ایجاد شود، آیتورز مختار به لغو رزرو است.</li>
                    <li>برای استرداد وجه مسافر ملزم به ارائه ی شماره کارت و شماره حساب و شبای حساب کارتی است که خرید را از طریق آن انجام داده است.</li>
                    <li>آیتورز اکیداً به مسافران توصیه‌ می‌کند پیش از سفر بیمه مسافرتی تهیه‌کنند. (مسئولیت داشتن این بیمه پیش از سفر بر عهده مسافر است)</li>
                    <li>با استناد به بخشنامه شماره ۹۱۰۶ تاریخ 03/03/1400 سازمان هواپیمایی کشوری در رابطه با تعرفه نظارت بر ایمنی مسافر (I6) در پروازهای داخلی و بین‌المللی، از تاریخ 11/03/140، بلیط پس از صدور، غیرقابل‌استرداد خواهد بود.</li>
                </ul>

                <h3 className='medium-title blue2'>قوانین بار</h3>
                <ul className='red-bullet-list'>
                    <li>آیتورز گزینه‌های متنوعی از تامین‌کنندگان مختلف ارائه می‌کند و بنابراین مسئولیت اتفاقاتی که در طول سفر رخ می‌دهند، مانند گم‌شدن بار، اضافه بار، لغو یا تغییر برنامه پرواز را به عهده نمی‌گیرد. مسئولیت این موارد برعهده خطوط هوای است و مسافران باید شکایات خود را به خط هوایی مربوطه ارجاع دهند.</li>
                    <li>مسافر باید دقت کند که بار او مغایرتی با قوانین ایرلاین نداشته ‌باشد. اطلاعات مربوط به بار بر روی وبسایت خط هوایی نوشته‌ شده‌است. </li>
                    <li>لطفا دقت کنید که قوانین بار در خطوط هوایی مختلف ، از یکدیگر متفاوت می باشد، لطفا برای کسب اطلاعات بیشتر در مورد بار اضافی و هزینه آن با خط هوایی تماس بگیرید. (این اطلاعات معمولا در وبسایت خطوط هوایی موجود است.)</li>
                    <li><b>بلیط های نوزاد در پروازهای داخلی هواپیمایی ماهان فاقد هرگونه بار مجاز می باشد.</b></li>
                    <li>
                        <p>توجه به رده سنی مسافران در هنگام ثبت اطلاعات بسیار مهم بوده و در صورت درج اطلاعات نادرست از ورود مسافر به هواپیما جلوگیری خواهد شد که مسئولیت آن بر عهده خود مسافر می باشد)</p>
                        <ul className='no-list-style'>
                            <li>رده سنی نوزاد = 10 روز تا دو سال</li>
                            <li>رده سنی کودک = بین 2 الی 12 سال تمام</li>
                            <li>رده سنی بزرگسال = بیش از 12 سال</li>
                        </ul>
                    </li>
                </ul>
                <h4 className='normal-title blue2'>بار ویژه</h4>
                <ul className='red-bullet-list'>
                    <li>در بسیاری از ایرلاین ها از مسافر خواسته می شود تا در صورت داشتن بار با شرایطی خاص، اطلاعات مورد نظر این بار را با خط هوایی مورد نظر به اشتراک بگذارد؛ لازم است که ابعاد (طول، عرض و ارتفاع) و و وزن بسته را اعلام کنید. </li>
                    <li>معمولا خط هوایی پس از چند ساعت جواب ایمیل شما را ارسال می‌کند. </li>
                    <li>هزینه این قبیل بارها به خود ایرلاین پرداخت می‌شود. </li>
                    <li>بارهای ویژه شامل مواردی مثل لوازم اسکی، گلف و آلات موسیقی هستند. </li>
                    <li>لطفا دقت داشته‌باشید که نمی‌توان بارهای بزرگ را در هواپیماهای کوچک جای داد. </li>
                    <li>برای بارهای ویژه، آیتورز، فقط می‌تواند درخواستی برای خط هوایی ارسال نماید؛ تا زمانی که خط هوایی، تاییدیه را صادر نکرده است، درخواست پذیرفته شده محسوب نمی شود.</li>
                </ul>
                
                <h4 className='normal-title blue2'>محتوای کیف دستی</h4>
                <ul className='red-bullet-list'>
                    <li>برای کسب اطلاعات در مورد به همراه‌‌داشتن مایعات، دارو، غذای کودک و غذاهای رژیمی خاص باید به وبسایت خط هوایی مراجعه‌کنید.</li>
                </ul>

                <h3 className='medium-title blue2'>سفر با کودکان</h3>
                <ul className='red-bullet-list'>
                    <li>امکان تهیه ی بلیط نوزاد یا کودک به تنهایی و جدا از بزرگسال به هیچ وجه ممکن نمی باشد.</li>
                    <li>وارد کردن سن دقیق کودکانی که در سفر همراه شما هستند، بسیار حائز اهمیت است. </li>
                    <li>سن کودکان توسط خط هوایی بررسی می‌شود. </li>
                    <li>دقت کنید که سن کودک در تاریخ برگشت تغییر نکرده باشد.</li>
                    <li> اگر سن کودک شما در طول سفر به دو سال تغییر می‌کند، در رزرو سن او را بالای دو سال ثبت کنید. </li>
                    <li>اگر سن کودک در سفر برگشت به بالای 12 سال تغییر کند، رزرو را با عنوان بزرگسال ثبت کنید. </li>
                    <li>کودکان زیر دو سال به عنوان نوزاد ثبت می‌شوند؛ این امر بدین معناست که آنها نیازی به صندلی ندارند. </li>
                    <li>خرید بلیط برای کودکی که هنوز به دنیا نیامده‌، امکان‌پذیر نمی باشد، چون در واقع خرید بلیط بدون ارائه نام و سایر اطلاعات امکان‌پذیر نیست.</li>
                    <li> آیتورز هیچ مسئولیتی در قبال پذیرش بلیط کودک،که بعد از فرایند خرید اضافه شده است، نخواهد داشت. </li>
                    <li>خط هوایی بودن جای اضافی برای نوزاد را تضمین نمی‌کند و از نظر فنی، گرفتن صندلی برای نوزاد، امکان‌پذیر نیست و نوزاد بر روی پای سرپرست خود خواهد نشست. </li>
                    <li>نمی توان بلیط نوزاد را در میانۀ برنامه سفر به بلیط خریداری شده اضافه کرد.</li>
                    <li>برای مثال اگر والدینی قصد دارند به سفر رفته و نوزاد خود را در کشوری دیگر به دنیا بی آورند (و برای سفر خود بلیط برگشت تهیه کرده اند) نمی توانند نوزاد متولد شده را به بلیط برگشت خود اضافه کنند. </li>
                    <li>همچنین نمی توان سفارش یک بلیط جداگانه برای نوزاد ثبت شود، بلکه باید بلیطی جدید برای یک بزرگسال و یک نوزاد صادر شود. </li>
                </ul>
                <h3 className='normal-title blue2'>سفر کودک بدون همراه</h3>
                <ul className='red-bullet-list'>
                    <li> کودکانی که به تنهایی به مسافرت می پردازند، از قبل باید سرویس کودک بدون همراه رزرو گردیده باشد.  هزینه این سرویس، و همچنین حداقل سن کودک، در هر خط هوایی، با یکدیگر متفاوت است.</li>
                    <li> استفاده از این سرویس تنها باید از یک خط هوایی بلیط تهیه شده، و ضمناً، نام، آدرس و تلفن فردی که در فرودگاه به استقبال کودک خواهد آمد در اطلاعات مورد نیاز ثبت شده و در اختیار آیتورز قرار گیرد.</li>
                    <li> درخواست مورد نظر از طرف آیتورز به ایرلاین مربوطه فرستاده شده ، و تا زمان تایید نهایی خط هوایی این درخواست رزرو قطعی محسوب نخواهد شد.</li>
                </ul>

                <h3 className='medium-title blue2'>شرایط ویژه</h3>
                <ul className='red-bullet-list'>
                    <li>خریداران محترم توجه فرمایید، برای سفر به بعضی مناطق و کشورها باید واکسن های خاصی را استفاده نمایید.</li>
                    <li>افرادی که مبتلا به بیماری هستند و یا از داروهای تضعیف کننده سیستم ایمنی استفاده میکنن، جهت ورود به هواپیما ملزم به تهیه مجوز پزشکی، از پزشک معتمد ایرلاین هستند. (مسافر موظف است در این زمینه مستقیما با ایرلاین در ارتباط باشد، در غیر این صورت مسئولیت عواقب احتمالی آن بر عهده مسافر است) همچنین توصیه می شود داروهای مورد نیاز در طول سفر همراه مسافر باشد</li>
                    <li>لازم به ذکر است چنانچه ایرلاین علی رقم وجود مجوز پزشکی تشخیص دهد سفر برای مسافر خطر آفرین است، می تواند از پرواز فرد ممانعت نماید و کنسلی بلیط در چنین شرایطی مطابق قوانین اولیه بلیط می باشد وآیتورز هیچ گونه مسئولیتی در این زمینه ندارد.</li>
                    <li>در صورت نیاز به استفاده از خدمات خاص مثل (ویلچر، ماسک اکسیژن در حین پرواز و ...) باید با واحد پشتیبانی آیتورز در ارتباط باشید.</li>
                    <li>توجه داشته باشید صرف ارائه درخواست به پشتیبانی آیتورز، منزله تایید نیست و باید هماهنگی و بررسی لازم با ایرلاین در این زمینه صورت گیرد.</li>
                </ul>

                <h3 className='medium-title blue2'>شرایط پذیرش حیوان خانگی در پرواز</h3>
                <ul className='red-bullet-list'>
                    <li>شرایط حمل حیوان خانگی با توجه به  قوانین هر کشور و ضوابط ایرلاین های مختلف متفاوت است.</li>
                    <li>امکان ورود حیوان خانگی به داخل کابین در ایرلاین های ایرانی میسر نمی باشد و صرفا در قسمت بار امکان پذیر است،  این ضوابط در مورد هر ایرلاین متفاوت بوده و لازم است خود مسافر از طریق سایت ایرلاین مورد نظر ضوابط را بررسی نماید.</li>
                    <li>در ایرلاین های خارجی نیز شرایط و ضوابط هر ایرلاین برای ورود حیوان خانگی به قسمت کابین و یا بار متفاوت بوده که توصیه می شود مسافران قبل خرید بلیط از سایت ایرلاین این مورد را بررسی نمایند.</li>
                    <li>جهت حمل حیوان خانگی، به همراه داشتن مدارک کامل از جمله شناسنامه و گواهی بهداشت حیوان خانگی ضروری است.</li>
                    <li>با توجه به ظرفیت محدود برای حمل حیوان خانگی، ممکن است درخواست شما از طرف ایرلاین رد شود و این مساله ارتباطی با آیتورز ندارد.</li>
                </ul>
                <h4 className='normal-title blue2'>آنچه که باید درباره مهاجرت با حیوان خانگی بدانید</h4>
                <p>با توجه به وجود مراحل متعدد و متفاوت هر کشور در زمینه سفر به همراه حیوان خانگی، که بعضاً ممکن است زمانی برابر سه ماه الی یکسال طول بکشد. خواهشمند است با زمانبندی مناسب به طی کردن هر یک از مراحل بپردازید، عواقب عدم توجه به این موضوع بر عهده مسافر می باشد.</p>

                <h3 className='medium-title blue2'>بلیط و پرواز</h3>
                <ul className='red-bullet-list'>
                    <li>در ازای هر مسافر یک شماره بلیط صادر شده و مسئولیت اطمینان از این موضوع برعهدۀ خود مسافر می باشد، این امر بدین معنا است که برای یک مسیر تک سفره، هر مسافر باید دارای یک شماره بلیط بوده، و در غیراین صورت، مسافر باید در اسرع وقت با آیتورز تماس حاصل نماید.</li>
                    <li>اگر به صورت گروهی سفر می کنید، به یاد داشته باشید که تمامی مسافران شماره بلیط (هر نفر یک شماره) را باید دریافت کرده باشند.</li>
                    <li>آیتورز بلیط رفت و برگشت را نیز به صورت دو بلیط مجزا ارائه می‌کند؛ قوانین در مورد هر دو بلیط اعمال می‌شود. </li>
                    <li>پرواز غیرمستقیم پروازی است که در چندجا توقف دارد و هواپیما و شماره پرواز در حین سفر تغییر می‌کند. </li>
                    <li>اگر در پرواز غیرمستقیم مسافر مجبور به تغییر فرودگاه شود، هزینه جابه‌جایی بین دو فرودگاه برعهده مسافر است.</li>
                    <li>زمانی که فرودگاه تغییر می‌کند، مسافر ملزم به تحویل گیری بار خود، و تحویل آن به خدمۀ پرواز بعدی می باشد.</li>
                    <li> گاهی مسافر برای پرواز و حرکت هواپیمای بعدی یک شب باید صبر نماید؛ هزینۀ اقامت در این مدت برعهدۀ خود مسافر است.</li>
                    <li>اگر پرواز اول در سفری چند مسیره تاخیر داشته‌ و مسافر دیر به پرواز بعدی برسد، مسئولیت رساندن مسافر به مقصد برعهده خط هوایی است، اما اگر بلیط‌ها به صورت جداگانه خریداری شده‌باشند، خط هوایی مسئولیتی در قبال جاماندن مسافر از پرواز، به دلیل تاخیر اول نخواهد داشت. بنابراین مسافر باید اطمینان حاصل کند که برای رسیدن به پرواز بعدی زمان کافی را در اختیار دارد و  مسئولیت بررسی زمان پروازها پیش از سفارش برعهده وی است.</li>
                    <li>تا زمانی که شماره پرواز تغییر پیدا نکند، اگر هواپیمایی برای گرفتن سوخت، به زمین بشیند، باز هم پرواز مستقیم محسوب خواهد شد.</li>
                    <li>این قبیل توقف‌ها طبق قوانین یاتا قابل قبول است و پروازی بدون توقف محسوب می‌شود، که از نقطه A مستقیما به نقطه B می رود.</li>
                    <li>اطلاع رسانی جهت نهایی شدن سفارش و اعلام تغییرات در پروازهای داخلی از طریق پیامک و در پروازهای خارجی از طریق ایمیل و پیامک به مسافر صورت خواهد گرفت، لذا صحت شماره وارده و اطمینان از در دسترس بودن شماره بر عهده مسافر می باشد.</li>
                    <li>از زمان ثبت رزرو تا تاریخ رفت، لطفا مرتباً پیامک و ایمیل خود را چک کرده زیرا کلیه ارتباطات آیتورز و مسافران بعد از انجام رزرو از این طریق این راه های ارتباطی می باشد.</li>
                    <li>خواهشمندیم پس از نهایی شدن خرید از دریافت پیامک و ایمیل از جانب آیتورز اطمینان حاصل نمایید و در غیر این صورت در اسرع وقت با پشتیبانی آیتورز تماس حاصل فرمایید و از خرید مجدد خود داری کنید. (خط هوایی بدون دادن اخطار، دو رزرو یکسان را کنسل خواهدکرد) در این صورت آیتورز مسئولیتی در قبال کنسلی خطوط هوایی یا بازگرداندن هزینه بلیط برعهده نخواهد داشت.</li>
                    <li>مدت زمان ارسال بلیط 24 ساعت بوده و بعد از تکمیل فرایند خرید به ایمیل مسافر فرستاده می شود.</li>
                    <li>مسئولیت اطلاع از زمان درست پرواز رفت و برگشت برعهده مسافر است. </li>
                    <li>تمامی زمان‌های قید شده در بلیط‌ها به وقت محلی فرودگاه‌هاست. </li>
                    <li>زمان توقف هواپیما در بین راه که در بلیط قید شده‌است، مطابق با زمان محلی است. </li>
                    <li>برای انجام پرداخت، می‌توانید از طریق درگاه آنلاین اقدام نمایید و لازم است در زمان سفارش کل مبلغ را پرداخت نمایید. </li>
                    <li>پس از ثبت سفارش، زمانی رزرو شما قطعی خواهد شد که هزینه آن را پرداخت کنید. </li>
                    <li>تنها رزروهایی قطعی خواهند بود که هزینه ی آنها، به طور کامل پرداخت گردیده باشد. </li>
                    <li>مبلغ پرداخت شده برای بلیط شامل هزینه حمل و نقل و یا اقامت در بین مسیرها نیست. </li>
                    <li>واریز وجه به صورت علی الحساب، عدم اطلاع و ثبت فیش بانکی بدون مشخصات، اختلال در سیستم پرداخت آنلاین؛ بدین معنا که مبلغ مورد نظر به حساب بانکی آیتورز واریز نشده باشد؛ رزرو قطعی محسوب نشده و آیتورز هیچگونه مسئولیت درقبال موارد فوق الذکر نخواهد داشت. </li>
                    <li>در صورت پرداخت وجه رزرو به صورت غیرآنلاین، به دلیل مشخص نبودن اطلاعات واریزکننده، مسافر می بایست تصویر فیش واریزی را به ایمیل بخش پشتیبانی ارسال و جهت صدور بلیط با همان بخش هماهنگ نماید .  </li>
                    <li>زمانی‌که سفارش خود را ثبت و هزینه آن ‌را پرداخت کردید، تاییدیه سفارش به آدرس ایمیلی که در زمان رزرو ثبت کرده‌اید، ارسال خواهدشد، در این ایمیل لینکی به صفحه رزرو شخصی شما وجود دارد که از طریق آن می‌توانید، بلیط خود را به صورت الکترونیکی چاپ کنید. (بلیط سفر شما الکترونیکی است و دیگر بلیط کاغذی به شما داده‌نخواهد شد)</li>
                    <li>توصیه می‌کنیم که پیش از سفر بلیط خود را چاپ نمایید. (در این بلیط اطلاعات اصلی مثل شماره بلیط و زمان پرواز نوشته‌شده‌است)</li>
                    <li>در پروازهای چارتری ارائهی یک نسخه چاپ شده از بلیط و در پروازهای سیستمی، ارائه شماره بلیط به همراه مدرک معتبر شناسایی الزامی است.</li>
                    <li>برای تغییر دادن یا افزودن اطلاعات به سفارش خود، در زمان تماس با آیتورز، باید شماره رزرو را ارائه کنید.</li>
                    <li>لازم به ذکر است، قیمت بلیط بزرگسال، کودک و نوزاد در بلیط های سیستمی (بلیط های که تابع قانون هواپیمایی کشوری می باشند و با نرخ های شناور به فروش می رسد) متفاوت می باشد، در حالیکه در بلیط های چارتری(بلیطی هایی که نرخ های آن توسط آژانس های چارتر کننده تعیین میشود و استرداد آن تابع قوانین سازمان هواپیمایی کشوری نمی باشد)  به این صورت نیست.</li>
                    <li>قیمت اعلام شده بلیط از جانب آیتورز و مفاد آن رسمی بوده و خریدار حق هیچ گونه دخل و تصرفی در آن ندارد.</li>
                    <li>
                        از مسافرین گرامی خواهشمندیم به موقع در فرودگاه و ترمینال درج شده در بلیط حاضر باشند:
                        <ul className='margin-top-light'>
                            <li>پروازهای داخلی یک ساعت قبل از زمان پرواز </li>
                            <li>پروازهای خارجی حداقل چهار ساعت قبل از زمان پرواز</li>
                        </ul>
                    </li>
                    <li>
                        پیش از سفر ترمینال فرودگاه خود را با ایرلاین مربوطه چک نمایید.
                        <ul className='margin-top-light'>
                            <li>ترمینال 1: کیش‌ایر، زاگرس، وارش</li>
                            <li>ترمینال 2: ایران ایر، ایر تور، آتا، قشم ایر، معراج، کارون</li>
                            <li>ترمینال 4: ماهان، کاسپین، آسمان، اترک، تابان، سپاهان، فلای پرشیا</li>
                            <li>ترمینال 4 یا 6 : سپهران، پویا، ساها (قبل از سفر، از اطلاعات فرودگاه مهرآباد استعلام شود)</li>
                        </ul>
                    </li>
                    <li>در صورت عدم استفاده از پرواز رفت در پرواز های خارجی سیستمی، بلیط برگشت توسط ایرلاین باطل می گردد.</li>
                </ul>

                <h3 className='medium-title blue2'>قوانین پرواز داخلی</h3>
                <p>چنانچه پرواز لغو شود و یا بعلت تاخیر بالای دو ساعت مسافر درخواست انصراف داشته باشد، باید بلیط و یا رسید خود را نزد کانتر هواپیمایی مهر زده . بر اساس قوانین شرکت هواپیمایی مربوطه، نسخه مورد تایید را به محل شرکت ارسال نماید، هزینه پرواز پس از تایید ایرلاین مربوطه به مسافر بازگردانده خواهد شد. چنانچه مسافر پیش از رفتن به فرودگاه از لغو پرواز مطلع شود، با تماس با واحد پشتیبانی آیتورز می تواند بدون پرداخت جریمه بلیط خود را کنسل نماید و پرواز جایگزین تهیه کند، لازم به ذکر است چنانچه بلیط رفت و برگشت با دو ایرلاین متفاوت باشد، در صورت لغو پرواز و یا انصراف باید اصل بلیط مهر شده را به دفتر آیتورز ارسال نماید، ممکن است بدلیل قوانین بین ایرلاین ها لغو پرواز برگشت شامل جریمه کنسل شود.</p>

                <h3 className='medium-title blue2'>قوانین کنسلی بلیط رفت و برگشت داخلی</h3>
                <p>
                چنانچه بلیط پرواز به صورت دو طرفه (رفت و برگشت) خریداری گردد، در صورتی که یک مسیر پروازی توسط ایرلاین لغو شود و یا با تاخیر و تعجیل بیش از دو ساعت همراه باشد و مسافر از پرواز انصراف دهد، قوانین درج شده در پایین جهت کنسل کردن مسیر دیگر صدق می کند. 
                </p>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center">
                        <thead>
                            <tr>
                                <th>ایرلاین</th>
                                <th>فاصله پرواز رفت و برگشت</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>ایران ایر</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={iranAirLogo} alt="ایران ایر" />
                                        </span>
                                    </span>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>کاسپین</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={caspianLogo} alt="کاسپین" />
                                        </span>
                                    </span>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>آتا</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={ataLogo} alt="آتا" />
                                        </span>
                                    </span>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>آسمان</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={asemanLogo} alt="آسمان" />
                                        </span>
                                    </span>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>ماهان</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={mahanLogo} alt="ماهان" />
                                        </span>
                                    </span>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>پارس ایر</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={parsAirLogo} alt="پارس ایر" />
                                        </span>
                                    </span>                                    
                                </td>
                                <td className='bold'>
                                    کمتر از 3 ساعت
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>کارون</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={karonLogo} alt="کارون" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>قشم ایر</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={gheshmAirLogo} alt="قشم ایر" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>کیش ایر</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={kishAirLogo} alt="کیش ایر" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>سپهران</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={sepehranLogo} alt="سپهران" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>ساها</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={sahaLogo} alt="ساها" />
                                        </span>
                                    </span> 
                                </td>
                                <td className='bold'>
                                    کمتر از 48 ساعت
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>معراج</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={meragLogo} alt="معراج" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>تابان</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={tabanLogo} alt="تابان" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>وارش</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={vareshLogo} alt="وارش" />
                                        </span>
                                    </span>  
                                </td>
                                <td className='bold'>
                                    کمتر از 24 ساعت
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} className="bg-gray bold">
                                    در صورت لغو یا تاخیر و تعجیل بیش از 2 ساعت (با فاصله زمانی ذکر شده) پرواز رفت یا برگشت بدون جریمه استرداد خواهد شد.
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>زاگرس</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={zagrosLogo} alt="زاگرس" />
                                        </span>
                                    </span> 
                                    <span className='airline-item inline-block'>
                                        <span className='airline-title'>ایران ایر تور</span>
                                        <span className='inline-block-middle margin-start-light'>
                                            <img className='airline-logo' src={iranAirTourLogo} alt="ایران ایر تور" />
                                        </span>
                                    </span>                                      
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} className="bg-gray bold">
                                در صورت لغو یا تاخیر و تعجیل بیش از 2 ساعت پرواز رفت،  پرواز برگشت با جریمه استرداد خواهد شد.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <h4 className='normal-title blue2'>درصد جریمه استرداد پرواز داخلی</h4>
                {airlinesCancellationTables} 
                <h4 className='normal-title blue2'>بار مجاز پرواز داخلی</h4>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={3}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={iranAirLogo} alt="ایران ایر" />
                                        <span className='airline-title'>ایران ایر</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>Y S V Q K M N X L O</td>
                                <td>20 کیلوگرم </td>
                                <td rowSpan={2}>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>C J I</td>
                                <td>30 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={4}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={asemanLogo} alt="آسمان" />
                                        <span className='airline-title'>آسمان</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>U R X Q N M L K H B O E</td>
                                <td>20 کیلوگرم </td>
                                <td rowSpan={3}>0 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>Y V S</td>
                                <td>25 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>D I Z</td>
                                <td>30 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={3}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={mahanLogo} alt="ماهان" />
                                        <span className='airline-title'>ماهان</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>N T V L Q U W WW WA WS WQ X B BH S Y</td>
                                <td>20 کیلوگرم </td>
                                <td rowSpan={2}>0 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>P C CC I R</td>
                                <td>25 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={2}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={ataLogo} alt="آتا" />
                                        <span className='airline-title'>آتا</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>Y R O V B Q P N M L A D K W H F E S I</td>
                                <td>20 کیلوگرم </td>
                                <td>10 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={4}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={tabanLogo} alt="تابان" />
                                        <span className='airline-title'>تابان</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>Y YD YF YK YN YP</td>
                                <td rowSpan={3}>20 کیلوگرم </td>
                                <td rowSpan={3}>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>T R V Z L M A I P F C K D N</td>
                            </tr>
                            <tr>
                                <td>B W J S O Q E U H X G</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={17}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={gheshmAirLogo} alt="قشم ایر" />
                                        <span className='airline-title'>قشم ایر</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>A Y V M K N Q R</td>
                                <td>20 کیلوگرم </td>
                                <td>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>J JF JB JH JD</td>
                                <td rowSpan={15}>20 کیلوگرم</td>
                                <td rowSpan={15}>0 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>Z ZF ZB ZH ZD</td>
                            </tr>
                            <tr>
                                <td>Q QF QB QH QD</td>
                            </tr>
                            <tr>
                                <td>W WF WB</td>
                            </tr>
                            <tr>
                                <td>N NF NB</td>
                            </tr>
                            <tr>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>S SF</td>
                            </tr>
                            <tr>
                                <td>H HF</td>
                            </tr>
                            <tr>
                                <td>P PF PB</td>
                            </tr>
                            <tr>
                                <td>R RF RB RH RD RE</td>
                            </tr>
                            <tr>
                                <td>M</td>
                            </tr>
                            <tr>
                                <td>K KF</td>
                            </tr>
                            <tr>
                                <td>L LF LB LH</td>
                            </tr>
                            <tr>
                                <td>I IF IB IH IE</td>
                            </tr>
                            <tr>
                                <td>V A F U D</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={2}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={karonLogo} alt="کارون" />
                                        <span className='airline-title'>کارون</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>B S Y N Q K V X H L M R U D O E Z F P</td>
                                <td>20 کیلوگرم </td>
                                <td>0 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={3}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={kishAirLogo} alt="کیش ایر" />
                                        <span className='airline-title'>کیش ایر</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>J Y V R D A H B P Q M N L O E K U X S</td>
                                <td>20 کیلوگرم </td>
                                <td>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>C</td>
                                <td>30 کیلوگرم </td>
                                <td>0 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={20}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={zagrosLogo} alt="زاگرس" />
                                        <span className='airline-title'>زاگرس</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>C J (CIP)</td>
                                <td rowSpan={15}>20 کیلوگرم </td>
                                <td rowSpan={19}>0 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>Z ZD ZF ZI ZL</td>
                            </tr>
                            <tr>
                                <td>F FD FF FI FL FN FQ FS FU FW</td>
                            </tr>
                            <tr>
                                <td>I ID IF</td>
                            </tr>
                            <tr>
                                <td>T TD TF</td>
                            </tr>
                            <tr>
                                <td>U UD UF</td>
                            </tr>
                            <tr>
                                <td>A AD AF</td>
                            </tr>
                            <tr>
                                <td>B BD BF BI D DD</td>
                            </tr>
                            <tr>
                                <td>S</td>
                            </tr>
                            <tr>
                                <td>M MD</td>
                            </tr>
                            <tr>
                                <td>N ND</td>
                            </tr>
                            <tr>
                                <td>R RD RF</td>
                            </tr>
                            <tr>
                                <td>H HD</td>
                            </tr>
                            <tr>
                                <td>Q QD</td>
                            </tr>
                            <tr>
                                <td>L LD</td>
                            </tr>
                            <tr>
                                <td>P PD</td>
                                <td rowSpan={4}>15 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>V VD VF</td>
                            </tr>
                            <tr>
                                <td>Y YD K KD</td>
                            </tr>
                            <tr>
                                <td>W WD WF</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={4}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={meragLogo} alt="معراج" />
                                        <span className='airline-title'>معراج</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>P P1 Y Y1 Y2 Y3 B B1 B2 B3</td>
                                <td rowSpan={3}>20 کیلوگرم </td>
                                <td rowSpan={3}>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>M M1 M2 M3</td>
                            </tr>
                            <tr>
                                <td>H H1 H2 H3 H4 H5 H6</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={2}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={sepehranLogo} alt="سپهران" />
                                        <span className='airline-title'>سپهران</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>تمامی شناسه‌های نرخی</td>
                                <td>20 کیلوگرم </td>
                                <td>10 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={2}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={parsAirLogo} alt="پارس ایر" />
                                        <span className='airline-title'>پارس ایر</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>خروجی از قشم</td>
                                <td>20 کیلوگرم </td>
                                <td>10 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <p>در پروازهای خروجی از قشم ایربعلت محدودیت حجم بار، هر مسافر امکان حمل یک چمدان بسته باری متوسط (طول و عرض بین 58 تا 68 سانت و ارتفاع بین 61 تا 68 سانتی متر ) را دارد.</p>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={2}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={puyaLogo} alt="پویا" />
                                        <span className='airline-title'>پویا</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>تمامی شناسه‌های نرخی</td>
                                <td>15 کیلوگرم </td>
                                <td>0 کیلوگرم</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={7}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={vareshLogo} alt="وارش" />
                                        <span className='airline-title'>وارش</span>
                                    </span>
                                </td>
                                <td className='th width-400'>شناسه نرخی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>M V U P S R</td>
                                <td rowSpan={6}>20 کیلوگرم </td>
                                <td rowSpan={6}>0 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>MI VI UI PI SI RI WI LI KI QI YI NI HI</td>
                            </tr>
                            <tr>
                                <td>W L K Q Y N H I J</td>
                            </tr>
                            <tr>
                                <td>MN VN PN RN LN KN QN YN HN IN</td>
                            </tr>
                            <tr>
                                <td>UU VV MM LL KK QQ WW PP SS RR</td>
                            </tr>
                            <tr>
                                <td>HH II NN</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <div className='itours-table-holder responsive-table'>
                    <table className="table-2 text-center bold-frame">
                        <tbody>
                            <tr>
                                <td className='bg-gray airline-col airline-title' rowSpan={5}>
                                    <span className='airline-item'>
                                        <img className='airline-logo large' src={iranAirTourLogo} alt="ایران ایر تور" />
                                        <span className='airline-title'>ایران ایر تور</span>
                                    </span>
                                </td>
                                <td className='th width-400'>بازه قیمتی</td>
                                <td className='th width-400'>میزان بار بزرگسال و کودک </td>
                                <td className='th width-400'>میزان بار نوزاد</td>
                            </tr>
                            <tr>
                                <td>500,000  تومان</td>
                                <td rowSpan={4}>20 کیلوگرم </td>
                                <td rowSpan={4}>10 کیلوگرم</td>
                            </tr>
                            <tr>
                                <td>300,000  الی 499,000 تومان</td>
                            </tr>
                            <tr>
                                <td>155,000  الی 299,000 تومان</td>
                            </tr>
                            <tr>
                                <td>154,000  تومان و پایین تر</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                {/* <p>
                در پروازهای خروجی از قشم ایربعلت محدودیت حجم بار، هر مسافر امکان حمل یک چمدان بسته باری متوسط (طول و عرض بین 58 تا 68 سانت و ارتفاع بین 61 تا 68 سانتی متر ) را دارد.
                </p> */}
                <br/>
                <p>
                در پروازهای خروجی از قشم ایربعلت محدودیت حجم بار، هر مسافر امکان حمل یک چمدان بسته باری متوسط (طول و عرض بین 58 تا 68 سانت و ارتفاع بین 61 تا 68 سانتی متر ) را دارد.
                </p>

                <h3 className='medium-title blue2'>قوانین پرواز خارجی</h3>
                <ul className='red-bullet-list'>
                    <li>کاربر گرامی توجه داشته باشید کسر مبلغ از حساب شما به منزله قطعی بودن صدور بلیط نمی باشد، ممکن است در حین خرید بلیط پرواز خارجی بعلت تکمیل ظرفیت پرواز، پول از حساب شما کسر شود ولی بلیطی صارد نشود. نگران نباشید مبلغ کسر شده بحساب شما برگشت داده خواهد شد</li>
                    <li>اگر بلیط شما چندمسیره است، لطفا در نظر داشته باشید که باید بلیط پروازها دقیقا مطابق با برنامه سفر استفاده شوند، اگر مسافر آگاهانه یا ناآگاهانه، پروازی را از دست بدهد، خط هوایی سایر بلیط‌های سفر را بدون بازپرداخت هزینه، لغو خواهد کرد. </li>
                    <li>در کانتر پذیرش، زمانی‌که گذرنامه معتبر خود را نشان می‌دهید، به شما کارت پرواز داده ‌خواهد شد. </li>
                    <li>هم‌چنین می‌توانید کارت پرواز را به صورت آنلاین دریافت کنید. </li>
                    <li>بر طبق قوانین برخی از شرکت های هواپیمایی خارجی، ثبت حضور آنلاین یا همان Online check inدر وبسایت هواپیمایی یک ضرورت بوده ودر غیر این صورت کارت پرواز با مبلغی به عنوان جریمه صادر می شود و مسئولیت عواقب آن با مسافر است.</li>
                    <li>تمامی امور در ارتباط با ویزا و ویزای ترانزیت و اقامت بر عهده مسافر بوده و آیتورز مسئولیتی در این زمینه ندارد.</li>
                    <li>مسافر موظف است قبل از اقدام به خرید بلیط، تاریخ بلیط را با تاریخ ویزا مطابقت دهد. چنانچه اعتبار پاسپورت کمتر از 6 ماه (180 روز کامل) باشد اجازه خروج از کشور در فرودگاه به مسافر داده نمی شود و مسئولیت آن بر عهده مسافر می باشد.</li>
                    <li>در صورت تمدید پاسپورت بعد از خرید بلیط، حتما تصویر پاسپورت جدید را به مرکز پشتیبانی Online@itours.ir ایمیل کنید. در نظر داشته باشید بر طبق قوانین هواپیمایی، ممکن است به روز کردن پاسپورت شامل هزینه گردد که مسافر باید آن را پرداخت نماید</li>
                    <li>توقف پرواز در برخی کشورها نیازمند ویزای ترانزیت و یا گذرنامه معتبر جهت تردد می باشد؛ در صورت نقص مدارک مسافر از پرواز وی جلوگیری خواهد شد، مسافر موظف است پیش از خرید این موارد را با پشتیبانی آیتورز چک نماید .</li>
                </ul>

                <h3 className='medium-title blue2'>قوانین هتل</h3>
                <ul className='red-bullet-list'>
                    <li>پس از رزرو نهایی هتل امکان اصلاح نام و یا مشخصات مسافر و یا افزودن مسافر به یک اتاق وجود ندارد و اطلاعات و تعداد مسافرین می بایست به صورت صحیح و طبق پاسپورت مسافر وارد شده باشد.</li>
                    <li>پس از رزرو نهایی هتل امکان تغییر تاریخ در رزرو انجام شده وجود ندارد، و می بایست رزرو قبلی مسافر کنسل، جرایم کنسلی اعمال و رزرو جدید انجام شود. </li>
                    <li>چنانچه مسافر به هر دلیل اعم از مثبت شدن تست کرونا، ممنوع الخروج بودن و یا ممانعت مقامات محلی در بدو ورود به مقصد، عدم صدور ویزا از طرف کشور مقصد، یا در مواردی مانند جا ماندن از پرواز، لغو و تغییر پرواز، یا ممانعت ایرلاین از انجام پرواز و یا رعایت نکردن سن قانونی جهت ورود به هتل و یا هر علت دیگری، نتواند از هتل رزرو شده استفاده نماید، آیتورز هیچ مسئولیتی در این موارد نداشته و طبق قوانین کنسلی یا نوشویی هتل اعمال خواهد شد.</li>
                    <li>کنسل کردن رزرو هتل از طریق وب‌ سایت آیتوزر، شامل جریمه‌ی کنسلی می باشد. مهلت و جریمه کنسلی هر هتل به هنگام رزرو مشخص شده. در صورت کنسل کردن واچر هتل، علاوه بر هزینه جریمه مشخص شده، مبلغ 10% از مبلغ کل رزرو (حداقل 200،000 تومان) بابت هزینه خدمات از مبلغ پرداختی مسافر کسر و الباقی به حساب مسافر مسترد می شود. شایان ذکر است اگر رزرو به نام چند مسافر گرفته شده باشد جهت اعلام کنسلی و یا هرگونه پیگیری احتمالی بعدی تنها از طریق نماینده گروه امکان پذیر می باشد.</li>
                    <li>هزینه پرداختی مسافر تنها بابت رزرو اتاق و خدمات قید شده در واچر مسافر می باشد و شامل خدماتی نظیر ترانسفر فرودگاهی، راهنما و ... نمی باشد.</li>
                    <li>در برخی از هتلها در بدو ورود مسافر، اطلاعات کردیت کارت و یا مبلغی به عنوان امانت از مسافر دریافت شده و در صورت استفاده از خدمات غیر رایگان هتل از آن مبلغ کسر می شود.  </li>
                    <li>هزینه های اضافی جهت استفاده خدمات غیر رایگان هتل به عهده مسافر می باشد و جدای هزینه پرداختی مسافر جهت رزرو اتاق است.</li>
                    <li> در شب ژانویه، کریسمس و ایام خاص، گالا دینر و یا بعضی از برنامه ها در هتل شامل هزینه اضافه است که پرداخت هزینه های آن توسط مسافر در هتل اجباری است، لطفا قبل از رزرو هتل از سایت هتل و یا از طریق همکاران آیتورز اطلاعات لازم را کسب نمایید. </li>
                    <li>در  صورت ایجاد خسارت به هتل توسط مسافر، کلیه خسارات از مسافر دریافت خواهد شد.</li>
                    <li>هتلهای برخی از شهرها مبلغی نیز به عنوان مالیات شهری و یا هزینه مجزا بابت تابعیت های خاص از مسافر اخذ میکنند که پرداخت این مبلغ به عهده مسافر می باشد.</li>
                    <li>طبقه و ویوی اتاقهایی که ویو آنها در واچر قید نشده و نوع تخت کاملا بستگی به شرایط موجود بودن در زمان پذیرش مسافر در هتل را دارد. </li>
                    <li>چنانچه در زمان ورود مسافر به هتل برخی از خدمات و امکانات هتل به علت بازسازی، شرایط آب و هوا و ... قابل استفاده نباشد، آیتورز هیچگونه مسئولیتی در این موارد ندارد.</li>
                    <li>پذیرش مسافر در هتل ها (Check In)معمولا ساعت 14:00 و ساعت خروج12:00 ظهر می باشد. در صورتی که مسافر بعد از ساعت 18:00 وارد هتل خواهد شد، میباست به همکاران پشتیبانی آیتورز اطلاع داده تا هتل در جریان ورود دیرهنگام مسافر قرار گرفته و دیر کردن مسافر را فرض بر نوشویی نکند که در این صورت خسارتهای احتمالی به عهده مسافر خواهد بود. در صورت تحویل زودتر اتاق توسط مسافر و استفاده نکردن شبهای رزرو شده اتاق، مبلغی به مسافر مسترد نخواهد شد. </li>
                    <li>در صورتیکه تعداد مسافرین بیشتر از تعداد نفرات قید شده در واچر باشد، در صورت عدم پذیرش مسافرین اضافی و یا دریافت هزینه نفر اضافی از طرف هتل اعم از بزرگسال و کودک آیتورز مسئولیتی در این خصوص ندارد.</li>
                    <li>ورود به هتلها در برخی از شهرها مستلزم دارا بودن بیمه نامه مسافرتی معتبر میباشد که چک کردن این موارد به عنوان مدارک مورد نیاز سفر به عهده مسافر می باشد.</li>
                    <li>با توجه به شرایط فعلی، علی رغم اعلام ملیت از سمت سایت آیتورز، برخی از هتل ها از پذیرش مسافران ایرانی اجتناب می کنند. در صورت بروز این اتفاق، رزرو شما کنسل شده و یا هتلی با شرایط مشابه به شما پیشنهاد خواهد شد.</li>
                    <li>این امکان وجود دارد که بعد از پرداخت وجه از حساب مسافر و در حین خرید ظرفیت هتل مورد نظرتکمیل شود، در این صورت مبلغ بحساب مسافر برگردانده خواهد شد، همچنین ممکن است پس از پرداخت وجه، نیاز به پرداخت مابه التفاوت افزایش نرخ هتل باشد که مسافر می بایست پرداخت نماید. (صرف کسر مبلغ مبنی بر قطعی بودن واچز هتل نیست)</li>
                    <li>جهت اطلاع از هزینه های ابطال رزرواسیون هتل با پشتیبانی آیتورز در ارتباط باشید.</li>
                    <li>توجه داشته باشید شرایط هر هتل در مورد اتاق های 3 تخته هر هتل متفاوت است. توجه به عبارت Triple و extra bed در نام اتاق ضروری است در غیر این صورت هزینه نفر سوم مستقیما از طرف هتل از مسافر دریافت می شود.</li>
                    <li>چنانچه مسافر درخواست برای اتاق هایی با دو تخت یک نفره، تعجیل یا تاخیر در ورود، امکان استعمال دخانیات را داشته باشد، آیتورز صرفا نقش انتقال دهنده درخواست راداد و تایید نهایی بر عهده هتل می باشد. در صورت هزینه بر بودن هر کدام از درخواست ها مسافر موظف به پرداخت آن است.</li>
                    <li>توجه به شرایط سنی افراد در هنگام رزرو هتل ضروری است، غالب هتل های خارجی افراد بالای 4 سال را بزرگسال محسوب می کنند، این قانون در مورد هتل های داخلی 6 سال به بالا هست. در صورت عدم توجه به این مورد مسئولیت آن بر عهده مسافر است</li>
                    <li>در صورت عدم پذیرش مسافر از جانب هتل با واچر معتبر لطفا با پشتیبانی آیتورز در تماس باشید</li>
                    <li>پرداخت عوارض خروج از کشور بر عهده مسافر می باشد</li>
                    <li>پاسخگویی در مورد خدمات هتل بر عهده خود هتل مورد نظر است.</li>
                    <li>رزرو اتاق به منزله موافقت خریدار با شرایط کنسلی می باشد، لذا خواهشمندیم قبل از رزرو به این مهم توجه فرمایید.</li>
                    <li>در برخی از هتلها مخصوصا هتلهای ساحلی، پذیرش کودکان در هتل ممنوع است، مسئولیت کسب اطلاعات در خصوص محدودیت در پذیرش هتلها به عهده مسافر است و قبل از اقدام به رزرو میبایست از سایت هتل اطلاعات لازم توسط مسافر بررسی شود.</li>
                </ul>             

            </Panel>
        </Collapse>          
    </>)

}
export default TermsFa;